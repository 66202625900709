<template>
  <div>
    <transition name="slide">
      <div class="drawer" v-if="handler">
        <div class="drawer-header flex ai-c jc-sb fgap2">
          <h4 class="bold">{{title}}</h4>

          <button type="button" @click.prevent="$emit('close')">
            <span class="material-symbols-outlined" style="font-size: 2rem;">
              Close
            </span>
          </button>
        </div>

        <div class="drawer-content" v-if="this.$scopedSlots.content">
          <slot name="content" />
        </div>

        <div class="drawer-footer" v-if="this.$scopedSlots.footer">
          <slot name="footer" v-bind="{close}" />
        </div>
      </div>
    </transition>

    <transition name="fade">
      <div v-if="handler" class="drawer-overlay" @click.prevent="$emit('close')"></div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'VDrawer',
  props: {
    handler: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.drawer-overlay {
  position: fixed;
  inset: 0;
  background: rgb(0 0 0 / 0.6);
  z-index: 9999999;
}

.drawer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  min-width: 360px;
  max-width: 90vw;
  background-color: white;
  z-index: 99999999;
  display: flex;
  flex-direction: column;
}

.drawer-header,
.drawer-content,
.drawer-footer {
  padding: 1rem;
}

.drawer-content {
  flex: 1 1 auto;
  overflow: auto;
}

.drawer-header {
  border-bottom: 1px solid #e2e2e2;
}

.drawer-footer {
  border-top: 1px solid #e2e2e2;
}

.drawer-header button {
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
  position: fixed;
  z-index: 999999;
  transition: opacity .3s;
}

.fade-enter, .fade-leave-to {
  position: fixed;
  z-index: 999999;
  opacity: 0;
}

.slide-enter-active, .slide-leave-active {
  position: fixed;
  z-index: 9999999;
  transition: transform .3s;
}

.slide-enter, .slide-leave-to {
  position: fixed;
  z-index: 9999999;
  transform: translateX(100vw);
}
</style>
