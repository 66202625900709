<template>
  <div class="large-chart-line">
    <ul class="pv-2 ph-2 flex fgap2 w-full large-chart-line-series">
      <div
        class="flex fd-c fgap1 text-center"
        :style="{ flex: `0 1 ${100 / getLength()}%`, minHeight }"
        v-for="(item, index) in getSeries()"
        :key="`${item}-${index}`"
        :class="{ 'chart-opacity': item?.opacity }"
      >
        <p class="text-medium">{{ item?.opacity ? '' : formatValue(item.value) }}</p>
        <div
          :class="{
            'large-chart-line-bar': true,
            blue: true,
          }"
        >
          <div
            class="level"
            :style="`--percentage: ${(item.value / ceiledHighestValue) * 100}%`"
          ></div>
        </div>
        <p class="text-medium">
          {{ item.label }}
        </p>
      </div>
    </ul>

    <!-- <div class="chart-footer">
      <ul class="flex ai-c fgap2 jc-sb pv-1 ph-2 mb-0 w-full text-medium">
        <li
          :style="{ flex: `0 1 ${100 / getLength()}%` }"
          v-for="(item, index) in getLabels()"
          :key="`${item}-${index}`"
          class="text-center ov-h"
        >
          {{ item }}
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'VBarChart',
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    minHeight: {
      type: String,
      default: '400px',
    },
    checkFormatValue: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      fallbackSeries: [],
    };
  },
  methods: {
    getSeries() {
      if (this.series.length > 0) {
        return this.series;
      }
      return this.fallbackSeries;
    },
    formatValue(value) {
      if (!this.checkFormatValue) {
        return value;
      }
      return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 1,
      }).format(value);
    },
    getHeadings() {
      if (this.series.length > 0) {
        return this.series.map((serie) => this.formatValue(serie.value));
      }

      return this.fallbackSeries.map((serie) => this.formatValue(serie.value));
    },
    getLabels() {
      if (this.series.length > 0) {
        return this.series.map((serie) => serie.label);
      }

      return this.fallbackSeries.map((serie) => serie.label);
    },
    getValues() {
      if (this.series.length > 0) {
        return this.series.map((serie) => serie.value);
      }

      return this.fallbackSeries.map((serie) => serie.value);
    },
    getLength() {
      if (this.series.length > 0) {
        return this.series.length;
      }
      return this.fallbackSeries.length;
    },
    getHighestValue() {
      let values = [];

      if (this.series.length > 0) {
        values = this.series.map((serie) => serie.value);
      } else {
        values = this.fallbackSeries.map((serie) => serie.value);
      }

      return Math.max(...values);
    },
    getLowestValue() {
      let values = [];

      if (this.series.length > 0) {
        values = this.series.map((serie) => serie.value);
      } else {
        values = this.fallbackSeries.map((serie) => serie.value);
      }

      return Math.min(...values);
    },
    getLeftLabels() {
      const highestValue = this.ceilHighestValue();
      const divisors = [1000000, 100000, 10000, 1000, 100, 10, 1];
      let highestDivisor = 0;
      let calcDivisor = 0;
      const labels = [];

      for (let i = 0; i < divisors.length; i += 1) {
        const divisor = divisors[i];

        if (highestDivisor > 0 || calcDivisor > 0) {
          break;
        }

        if (highestValue / divisor >= 1) {
          highestDivisor = divisor;
          calcDivisor = divisors[i + 1] ?? 1;
          break;
        }
      }

      let calcNumber = highestDivisor;

      labels.push(calcNumber);
      while (calcNumber > 0) {
        labels.push(calcNumber - calcDivisor);
        calcNumber -= calcDivisor;
      }

      return labels;
    },
    ceilHighestValue() {
      return Math.ceil(this.getHighestValue() / 10) * 10;
    },
  },
  computed: {
    lowestValue() {
      let values = [];

      if (this.series.length > 0) {
        values = this.series.map((serie) => serie.value);
      } else {
        values = this.fallbackSeries.map((serie) => serie.value);
      }

      return Math.min(...values);
    },
    highestValue() {
      let values = [];

      if (this.series.length > 0) {
        values = this.series.map((serie) => serie.value);
      } else {
        values = this.fallbackSeries.map((serie) => serie.value);
      }

      return Math.max(...values);
    },
    ceiledHighestValue() {
      // console.log(Math.ceil(this.getHighestValue() / 1) * 1);
      return Math.ceil(this.getHighestValue() / 1) * 1;
    },
  },
  mounted() {},
};
</script>

<style scoped>
.large-chart-line {
  display: flex;
  flex-direction: column;
}

.large-chart-line-series {
  overflow: auto;
}

.large-chart-line-bar {
  height: 100%;
  width: 15px;
  margin: 0 auto;
  border-radius: 100px;
  /* background-color: #f2f7ff; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
}

.large-chart-line-bar .level {
  position: relative;
  pointer-events: none;
  background: red;
  border-radius: 100px;
  height: 30px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  min-height: 10px;
  height: var(--percentage);
  animation: largeChartLine 0.6s cubic-bezier(0.6, 0.1, 1, 0.8);
  transition: all 0.2s ease-in-out 0s;
}

.large-chart-line-bar.blue .level {
  background: #104375;
}

.large-chart-line-bar.red .level {
  background: linear-gradient(to top, #f26767 40%, #f2676799);
}
.large-chart-line-bar.green .level {
  background: linear-gradient(to top, #137700 40%, #13770099);
}

.large-chart-line-bar span {
  font-weight: bold;
  color: white;
  display: block;
  position: relative;
  z-index: 2;
}

.chart-opacity {
  opacity: 0.3;
}

@keyframes largeChartLine {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
</style>
