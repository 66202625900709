<template>
  <div>
    <div class="relative" ref="gaugeChart">
      <svg
        :width="width"
        viewBox="0 0 120 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M114 60C114 30.1766 89.8234 6 60 6C30.1766 6 6 30.1766 6 60"
          stroke="#7B91B0"
          stroke-width="12"
          stroke-linecap="round"
        />
        <path
          d="M114 60C114 30.1766 89.8234 6 60 6C30.1766 6 6 30.1766 6 60"
          stroke="#F26767"
          stroke-width="12"
          stroke-linecap="round"
          stroke-dasharray="170"
          :stroke-dashoffset="170 + (170 * (percentage / 100))"
        />
      </svg>
      <div class="speedometer" :style="`--size: ${speedometerSize !== 0 ? speedometerSize : 70}px; --rotation: ${rotation}deg`"></div>
    </div>

    <div class="flex ai-c jc-sb fgap2 mt-1">
      <span style="flex: 0 1 33.33%" class="text-sm text-light">0</span>
      <span style="flex: 0 1 33.33%" class="text-center bold text-md" ref="stopValue">{{stopValue}}</span>
      <span style="flex: 0 1 33.33%" class="text-right text-sm text-light" ref="maxValue">{{maxValue}}</span>
    </div>

    <div class="mt-2">
      <p class="text-center text-medium">{{label}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VGauge',
  props: {
    width: {
      type: String,
      default: '200px',
    },
    stopValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      speedometerSize: 65,
    };
  },
  computed: {
    percentage() {
      return Number(((this.stopValue / this.maxValue) * 100).toFixed(2));
    },
    rotation() {
      const percentage = Number(((this.stopValue / this.maxValue) * 100).toFixed(2)) / 100;
      return 180 * percentage;
    },
  },
  methods: {
    checkResize() {
      this.speedometerSize = this.$refs.gaugeChart.clientHeight - this.$refs.gaugeChart.clientHeight * 0.35;
    },
    checkRotation() {

    },
  },
  mounted() {
    window.addEventListener('resize', this.checkResize);
    this.speedometerSize = this.$refs.gaugeChart.clientHeight - this.$refs.gaugeChart.clientHeight * 0.35;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkResize);
  },
};
</script>

<style scoped>
.speedometer {
  width: 50%;
  height: 1px;
  transform-origin: right center;
  transform: rotate(var(--rotation));
  position: relative;
  bottom: 10px;
  transition: all .2s ease-in-out 0s;
}

.speedometer::before {
  content: '';
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #F26767;
  position: absolute;
  right: -7px;
  bottom: -7px;
}

.speedometer::after {
  content: '';
  position: absolute;
  top: -7px;
  right: 0;
  width: 0;
  height: 0;
  display: block;
  border-style: solid;
  border-width: 7.5px var(--size) 7.5px 0;
  border-color: transparent #F26767 transparent transparent;
}
</style>
