<template>
  <div class="loading flex ai-c jc-c fd-c">
    <div class="loading-content flex fgap1">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
    <div class="loading-content flex text-center mt-6">
        <p>
            {{ label }}
            <br />
            <small> {{ description }} </small>
        </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  computed: {
    label() {
      return this.$store.state.loading.label;
    },
    description() {
      return this.$store.state.loading.description;
    },
  },
};
</script>

<style scoped>
  .loading {
    position: fixed;
    inset: 0;
    z-index: 99999999;
    background-color: rgba(255, 255 ,255, 0.85);
  }

  .loading-content span {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: var(--round-border-radius);
    background-color: var(--primary-color);
    animation: dots .6s cubic-bezier(0.6, 0.1, 1, 0.4) infinite alternate;
  }

  .loading-content p {
    font-size: 28px;
  }

  .loading-content p small {
    font-size: 15px;
  }

  .loading-content span:nth-child(1) {
    animation-delay: .1s;
  }

  .loading-content span:nth-child(2) {
    animation-delay: .2s;
  }

  .loading-content span:nth-child(3) {
    animation-delay: .3s;
  }

  .loading-content span:nth-child(4) {
    animation-delay: .4s;
  }

  @keyframes dots {
    from {
      transform: translate3d(0,0,0);
    }
    to {
      transform: translate3d(0, 30px, 0);
    }
  }
</style>
