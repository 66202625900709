<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9187 2.58328H15.9176L17.7772 2.95514C17.8771 2.97512 17.9592 3.04838 17.988 3.14717C18.0026 3.19516 18.0039 3.24621 17.9918 3.29488C17.9797 3.34354 17.9546 3.38802 17.9192 3.42357L16.3958 4.94874C16.2136 5.13075 15.9666 5.23295 15.709 5.23291H14.4464L11.2731 8.40869C11.3337 8.63738 11.3448 8.87635 11.3058 9.10968C11.2668 9.34301 11.1785 9.56534 11.0469 9.76187C10.9152 9.9584 10.7432 10.1246 10.5424 10.2494C10.3415 10.3742 10.1163 10.4548 9.88187 10.4857C9.64743 10.5166 9.40909 10.4972 9.18274 10.4288C8.95639 10.3603 8.74721 10.2444 8.56915 10.0887C8.39109 9.93307 8.24823 9.74122 8.15009 9.52598C8.05195 9.31075 8.00078 9.07706 8 8.84049C8.00017 8.58506 8.05903 8.33309 8.17205 8.10405C8.28507 7.87501 8.44921 7.67504 8.65178 7.51957C8.85436 7.36411 9.08996 7.25733 9.34036 7.20748C9.59077 7.15762 9.84927 7.16604 10.0959 7.23206L13.2703 4.05517V2.79308C13.2703 2.53555 13.3724 2.28802 13.5543 2.10597L15.0788 0.5808C15.1144 0.545422 15.1588 0.52034 15.2075 0.508222C15.2561 0.496104 15.3071 0.497402 15.3551 0.511978C15.4539 0.540839 15.5271 0.62298 15.5471 0.722883L15.9187 2.58328Z"
      fill="currentColor"
    />
    <path
      d="M1.79654 9.50386C1.79777 10.5338 2.01985 11.5514 2.44777 12.4882C2.8757 13.4249 3.49953 14.259 4.27719 14.9341C5.05484 15.6093 5.96823 16.1098 6.95574 16.4019C7.94325 16.6939 8.98191 16.7708 10.0017 16.6273C11.0214 16.4838 11.9985 16.1233 12.8671 15.57C13.7357 15.0167 14.4755 14.2836 15.0367 13.42C15.5978 12.5565 15.9673 11.5826 16.1201 10.5642C16.273 9.54568 16.2056 8.50626 15.9226 7.51602C15.8829 7.40033 15.8671 7.27776 15.8763 7.15578C15.8854 7.0338 15.9193 6.91495 15.9758 6.80648C16.0323 6.69801 16.1104 6.60218 16.2051 6.52483C16.2999 6.44747 16.4094 6.39021 16.5269 6.35653C16.6445 6.32285 16.7677 6.31345 16.8891 6.32892C17.0104 6.34438 17.1273 6.38437 17.2327 6.44646C17.3381 6.50856 17.4297 6.59145 17.5021 6.69009C17.5744 6.78874 17.6259 6.90107 17.6534 7.02026C18.2014 8.93791 18.0973 10.9832 17.3575 12.8353C16.6177 14.6875 15.284 16.2416 13.5658 17.2538C11.8475 18.2661 9.84182 18.6793 7.86341 18.4285C5.885 18.1778 4.04579 17.2773 2.63434 15.8683C1.22442 14.4571 0.32305 12.6175 0.0717465 10.6385C-0.179558 8.65944 0.233434 6.65295 1.24588 4.93406C2.25833 3.21517 3.8129 1.88122 5.66551 1.14163C7.51813 0.402042 9.56388 0.298706 11.4816 0.847845C11.7099 0.914601 11.9026 1.06904 12.0174 1.27741C12.1322 1.48578 12.1599 1.73113 12.0944 1.95986C12.0289 2.18858 11.8755 2.38208 11.6678 2.49806C11.4601 2.61404 11.2149 2.64307 10.9859 2.57881C9.91352 2.27111 8.78436 2.21673 7.68741 2.41996C6.59045 2.62318 5.5557 3.07847 4.66472 3.74991C3.77373 4.42135 3.05088 5.2906 2.55315 6.28912C2.05542 7.28764 1.79641 8.38814 1.79654 9.50386Z"
      fill="currentColor"
    />
    <path
      d="M5.6672 9.49971C5.66726 10.107 5.83314 10.7028 6.14693 11.2228C6.46073 11.7428 6.91053 12.1672 7.44783 12.4503C7.98513 12.7334 8.58952 12.8645 9.19582 12.8293C9.80213 12.7941 10.3873 12.5941 10.8883 12.2508C11.3892 11.9066 11.7864 11.4321 12.0371 10.8784C12.2878 10.3248 12.3823 9.71319 12.3105 9.10971C12.2911 8.96327 12.3111 8.81429 12.3684 8.67813C12.4256 8.54196 12.5182 8.42353 12.6365 8.33502C12.7547 8.24652 12.8945 8.19113 13.0413 8.17457C13.1881 8.15801 13.3366 8.18088 13.4716 8.24082C13.6066 8.29998 13.7235 8.39388 13.8104 8.51295C13.8972 8.63202 13.951 8.77199 13.9661 8.9186C14.0868 9.94982 13.8835 10.993 13.3844 11.9035C12.8853 12.8139 12.1152 13.5464 11.1809 13.9993C10.2466 14.4522 9.19451 14.603 8.17063 14.4307C7.14675 14.2585 6.20188 13.7718 5.46716 13.0382C4.73243 12.3046 4.24431 11.3605 4.07053 10.3369C3.89676 9.31324 4.04595 8.26091 4.4974 7.32593C4.94886 6.39095 5.68017 5.6197 6.58986 5.11923C7.49954 4.61876 8.54246 4.41388 9.57386 4.53305C9.6843 4.54329 9.79158 4.57548 9.88942 4.62773C9.98725 4.67998 10.0737 4.75123 10.1436 4.83731C10.2136 4.92339 10.2656 5.02256 10.2967 5.12902C10.3279 5.23548 10.3374 5.34708 10.3248 5.45728C10.3123 5.56748 10.2778 5.67406 10.2235 5.77077C10.1692 5.86749 10.0962 5.95239 10.0086 6.0205C9.92109 6.08861 9.82084 6.13857 9.71375 6.16743C9.60666 6.1963 9.49488 6.20349 9.38497 6.1886C8.91792 6.13437 8.44469 6.17956 7.99635 6.32121C7.548 6.46285 7.1347 6.69775 6.78358 7.01046C6.43246 7.32317 6.15147 7.70663 5.95907 8.13564C5.76666 8.56466 5.66719 9.02952 5.6672 9.49971Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'GoalIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
