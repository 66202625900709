<template>
  <span
    class="v-badge"
    :class="{
      success: theme == 'success',
      primary: theme == 'primary',
      danger: theme == 'danger',
    }"
  >
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'VBadge',
  props: {
    theme: {
      type: String,
      default: 'primary',
    },
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.v-badge {
  display: inline-flex;
  border-radius: 100px;
  font-weight: bold;
}

.v-badge.primary {
  background-color: rgb(16 67 117 / 15%);
  color: rgb(16 67 117);
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
}

.v-badge.success {
  background-color: rgb(70 160 53 / 15%);
  color: rgb(70 160 53);
  padding: 0.35rem 0.8rem;
  font-size: 0.8rem;
}

.v-badge.danger {
  background-color: rgb(242 103 103 / 15%);
  color: rgb(242 103 103);
  padding: 0.35rem 0.8rem;
  font-size: 0.8rem;
}
</style>
