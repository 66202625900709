<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5833 0.5H1.41667C1.04094 0.5 0.680609 0.652227 0.414932 0.923193C0.149255 1.19416 0 1.56167 0 1.94487V6.12736C0.000351342 6.40501 0.0791352 6.67669 0.226924 6.90987C0.374713 7.14305 0.585247 7.32786 0.833333 7.44219L6.05 9.86023C5.27758 10.3011 4.67035 10.9908 4.32341 11.8213C3.97647 12.6518 3.90942 13.5762 4.13277 14.4498C4.35612 15.3233 4.85724 16.0966 5.55765 16.6486C6.25807 17.2006 7.1182 17.5 8.00333 17.5C8.88847 17.5 9.7486 17.2006 10.449 16.6486C11.1494 16.0966 11.6505 15.3233 11.8739 14.4498C12.0972 13.5762 12.0302 12.6518 11.6833 11.8213C11.3363 10.9908 10.7291 10.3011 9.95667 9.86023L15.1667 7.44219C15.4148 7.32786 15.6253 7.14305 15.7731 6.90987C15.9209 6.67669 15.9996 6.40501 16 6.12736V1.94487C16 1.56167 15.8507 1.19416 15.5851 0.923193C15.3194 0.652227 14.9591 0.5 14.5833 0.5ZM10.6667 1.85988V8.03629L8 9.27208L5.33333 8.03629V1.85988H10.6667ZM1.33333 6.12736V1.94487C1.33333 1.92233 1.34211 1.90071 1.35774 1.88477C1.37337 1.86884 1.39457 1.85988 1.41667 1.85988H4V7.41754L1.3825 6.2047C1.36788 6.198 1.35546 6.18713 1.34674 6.17342C1.33802 6.1597 1.33337 6.14371 1.33333 6.12736ZM8 16.1386C7.47258 16.1386 6.95701 15.9791 6.51848 15.6803C6.07995 15.3814 5.73815 14.9566 5.53632 14.4597C5.33449 13.9627 5.28168 13.4158 5.38457 12.8883C5.48747 12.3607 5.74144 11.8761 6.11438 11.4957C6.48732 11.1153 6.96248 10.8563 7.47976 10.7514C7.99704 10.6464 8.53322 10.7003 9.02049 10.9061C9.50776 11.112 9.92424 11.4606 10.2173 11.9078C10.5103 12.3551 10.6667 12.8809 10.6667 13.4189C10.6667 14.1402 10.3857 14.832 9.88562 15.342C9.38552 15.8521 8.70724 16.1386 8 16.1386ZM14.6667 6.12736C14.6666 6.14371 14.662 6.1597 14.6533 6.17342C14.6445 6.18713 14.6321 6.198 14.6175 6.2047L12 7.41754V1.85988H14.5833C14.6054 1.85988 14.6266 1.86884 14.6423 1.88477C14.6579 1.90071 14.6667 1.92233 14.6667 1.94487V6.12736Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'AwardIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
