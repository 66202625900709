import Vue from 'vue';
import VueRouter from 'vue-router';

import Dashboard from '@/components/Dashboard.vue';

import Login from '@/views/Auth/Login.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import RecoveryCode from '@/views/Auth/RecoveryCode.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';

import MyData from '@/views/MyData.vue';

import Home from '@/views/Home.vue';
import Processes from '@/views/Processes.vue';
import ProcessesReport from '@/views/ProcessesReport.vue';
import CalendarView from '@/views/CalendarView.vue';
import AnalyticsView from '@/views/Analytics/AnalyticsView.vue';
import NewCalendarView from '@/views/NewCalendarView.vue';
import OperationalCalendarView from '@/views/OperationalCalendarView.vue';

Vue.use(VueRouter);

const routes = [{
        path: '/',
        component: Dashboard,
        children: [{
                path: '/',
                name: 'Home',
                component: Home,
                props: {
                    pageTitle: 'Portal dos Clientes',
                    breadcrumbName: 'Painel Geral de Indicadores dos Processos',
                },
            },
            {
                path: '/processos/:id?',
                name: 'Processes',
                component: Processes,
                props: {
                    pageTitle: 'Processos Disponíveis',
                    breadcrumbName: 'Processos de Importação',
                },
            },
            {
                path: '/financeiro/previsao-numerario',
                name: 'FinanceiroPrevisaoNumerario',
                component: CalendarView,
                props: {
                    pageTitle: 'Calendário Financeiro',
                    breadcrumbName: '',
                },
            },
            {
                path: '/financeiro/previsao-numerario-novo',
                name: 'FinanceiroPrevisaoNumerarioNovo',
                component: NewCalendarView,
                props: {
                    pageTitle: 'Calendário Financeiro',
                    breadcrumbName: '',
                },
            },
            {
                path: '/operacional/calendario',
                name: 'OperacionalCalendario',
                component: OperationalCalendarView,
                props: {
                    pageTitle: 'Calendário Operacional',
                    breadcrumbName: '',
                },
            },
            {
                path: '/relatorio',
                name: 'ProcessesReport',
                component: ProcessesReport,
                props: {
                    pageTitle: 'Relatório Geral',
                    breadcrumbName: 'Relatório Geral',
                },
            },
            {
                path: '/meus-dados',
                name: 'MyData',
                component: MyData,
            },
            {
                path: '/analytics',
                name: 'Analytics',
                component: AnalyticsView,
                props: {
                    pageTitle: 'Análise Operacional',
                    breadcrumbName: 'Análise Operacional',
                },
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/esqueci-minha-senha',
        name: 'ForgotPassword',
        component: ForgotPassword,
    },
    {
        path: '/codigo-verificacao',
        name: 'RecoveryCode',
        component: RecoveryCode,
    },
    {
        path: '/redefinir-senha/:pin',
        name: 'ResetPassword',
        component: ResetPassword,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

const openRoutes = [
    'Login',
    'ForgotPassword',
    'RecoveryCode',
    'ResetPassword',
    'ForwardersShippingQuote',
];

router.beforeEach((to, from, next) => {
    if ((to.name === 'Login' && localStorage.getItem('@GSCMX-PC:access_token')) || (to.name === 'ForgotPassword' && localStorage.getItem('@GSCMX-PC:access_token'))) {
        next({ name: 'Home' });
    } else if (openRoutes.find((route) => route === to.name) === to.name) {
        next();
    } else if (openRoutes.find((route) => route === to.name) !== to.name) {
        if (!localStorage.getItem('@GSCMX-PC:access_token')) {
            localStorage.setItem('previousRoute', to.fullPath);
            next({ name: 'Login' });
        }
        next();
    } else {
        next();
    }
});

export default router;