export const modalLabels = {
  AEREA: 'Áereo',
  MARITIMA: 'Marítimo',
  RODOVIARIA: 'Rodoviário',
};

export const modalLabelsFem = {
  AEREA: 'Áerea',
  MARITIMA: 'Marítima',
  RODOVIARIA: 'Rodoviária',
};

export const modalSelectOptions = [
  {
    label: 'Áerea',
    value: 'AEREA',
  },
  {
    label: 'Marítima',
    value: 'MARITIMA',
  },
  {
    label: 'Rodoviária',
    value: 'RODOVIARIA',
  },
];
