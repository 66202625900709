<template>
  <div
    class="v-card"
    :class="{
      white: theme === 'white',
      light: theme === 'light',
      shadow,
      bordered: bordered,
      'no-padding': noPadding,
      'mouse-pointer': mousePointer === true
    }"
    @click="$emit('clickCard')"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VCard',
  props: {
    theme: {
      type: String,
      default: 'white',
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    mousePointer: {
      type: Boolean,
      default: false,
    }
  },
};
</script>

<style scoped>
.v-card {
  border-radius: 20px;
  padding: 20px;
}

.v-card.bordered {
  border: 1px solid #f4f7f9;
}

.v-card.white {
  background-color: white;
}

.v-card.light {
  background-color: #f4f7f9;
}

.v-card.shadow {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.v-card.no-padding {
  padding: 0;
}

.mouse-pointer {
  cursor: pointer;
}

.mouse-pointer:hover {
  cursor: pointer !important;
  background-color: var(--primary-color) !important;
  color: white !important;
}

.mouse-pointer:hover p {
  color: white !important;
}
</style>
