<template>
  <div>
    <page-title :breadcrumbName="breadcrumbName" :pageTitle="pageTitle" class="mb-2"/>

    <div class="columns gap2">
        <v-card class="span-12">
            <div class="flex ai-c jc-sb fgap2">
                <!-- <div>
                <h2 class="mb-1">RELATÓRIO DE ANÁLISE OPERACIONAL</h2>
                <p class="text-light">
                    Faça o filtro de acordo com as informações que você deseja
                    visualizar
                </p>
                </div> -->

                <div class="flex ai-c fgap2">
                    <div>
                        <Select title="PERÍODO INICIAL" useDate v-model="filters.dataInicio"></Select>
                    </div>

                    <div>
                        <Select title="PERÍODO FINAL" useDate v-model="filters.dataFim"></Select>
                    </div>

                    <div>
                        <Select title="EMPRESAS" :multiple="true" :options="userCompanies" v-model="filters.companies_ids"/>
                    </div>

                    <div>
                        <button class="btn solid primary rounded text-uppercase" type="button" @click.prevent="generateData">Gerar</button>
                    </div>
                    <div>
                        <button class="btn solid primary rounded text-uppercase flex ai-c gap1" type="button" @click.prevent="drawerOpen = true" v-if="showFilters">
                            <span>Filtros</span>
                            <span class="material-symbols-outlined" style="font-size: 1rem"> Filter_Alt</span>
                        </button>
                    </div>
                </div>
                <div class="flex ai-c fgap2">
                    <div>
                        <button class="btn-excel" type="button" title="Exportar Excel" @click.prevent="downloadExcel">
                            <excel-icon :size="26"/>
                        </button>
                    </div>
                </div>
            </div>
        </v-card>

        <v-card class="span-2 flex fd-c fgap1" :class="{ 'placeholder-set': impostoPlaceholderLoading }" shadow>
            <h4 :class="{ 'placeholder-loading plw-50 plh-20': impostoPlaceholderLoading }">
                <span class="bold"> II </span>
                <span class="text-sm"> Total </span>
            </h4>
            <p class="text-bg bold text-wordbreak" :class="{ 'placeholder-loading plw-100 plh-30': impostoPlaceholderLoading }">
                {{ impostos?.total_ii?.periodo_atual }}
            </p>
            <div class="flex ai-c" :class="{ 'placeholder-loading plw-100 plh-20': impostoPlaceholderLoading }">
                <move-up-icon size="16" class="text-success" v-if="impostos?.total_ii?.variacao == 'UP'"/>
                <move-down-icon size="16" class="text-danger" v-else-if="impostos?.total_ii?.variacao == 'DOWN'"/>
                <p>
                    <span class="text-medium" :class="impostos?.total_ii?.variacao == 'UP' ? 'text-success': 'text-danger'">
                        {{ impostos?.total_ii?.porcentagem }}
                    </span>
                    P.A.
                </p>
                <VTooltipVue size="0.8rem" title="II:" :description="impostos?.total_ii?.periodo_anterior" :dateStart="dataInicioApiFiltro" :dateEnd="dataFimApiFiltro" v-show="!impostoPlaceholderLoading"></VTooltipVue>
            </div>
        </v-card>

        <v-card class="span-2 flex fd-c fgap1" :class="{ 'placeholder-set': impostoPlaceholderLoading }" shadow>
            <h4 :class="{ 'placeholder-loading plw-50 plh-20': impostoPlaceholderLoading }">
                <span class="bold"> IPI </span>
                <span class="text-sm">Total</span>
            </h4>
            <p class="text-bg bold text-wordbreak" :class="{ 'placeholder-loading plw-100 plh-30': impostoPlaceholderLoading }">
                {{ impostos?.total_ipi?.periodo_atual }}
            </p>
            <div class="flex ai-c" :class="{ 'placeholder-loading plw-100 plh-20': impostoPlaceholderLoading }">
                <move-up-icon size="16" class="text-success" v-if="impostos?.total_ipi?.variacao == 'UP'"/>
                <move-down-icon size="16" class="text-danger" v-else-if="impostos?.total_ipi?.variacao == 'DOWN'"/>
                <p>
                    <span class="text-medium" :class="impostos?.total_ipi?.variacao == 'UP' ? 'text-success' : 'text-danger'">
                        {{ impostos?.total_ipi?.porcentagem }}
                    </span>
                    P.A.
                </p>
                <VTooltipVue size="0.8rem" title="IPI:" :description="impostos?.total_ipi?.periodo_anterior" :dateStart="dataInicioApiFiltro" :dateEnd="dataFimApiFiltro" v-show="!impostoPlaceholderLoading"></VTooltipVue>
            </div>
        </v-card>

        <v-card class="span-2 flex fd-c fgap1" :class="{ 'placeholder-set': impostoPlaceholderLoading }" shadow>
            <h4 :class="{ 'placeholder-loading plw-50 plh-20': impostoPlaceholderLoading }">
                <span class="bold"> PIS </span>
                <span class="text-sm">Total</span>
            </h4>
            <p class="text-bg bold text-wordbreak" :class="{ 'placeholder-loading plw-100 plh-30': impostoPlaceholderLoading }">
                {{ impostos?.total_pis?.periodo_atual }}
            </p>
            <div class="flex ai-c" :class="{ 'placeholder-loading plw-100 plh-20': impostoPlaceholderLoading }">
                <move-up-icon size="16" class="text-success" v-if="impostos?.total_pis?.variacao == 'UP'"/>
                <move-down-icon size="16" class="text-danger" v-else-if="impostos?.total_pis?.variacao == 'DOWN'"/>
                <p>
                    <span class="text-medium" :class="impostos?.total_pis?.variacao == 'UP' ? 'text-success' : 'text-danger'">
                        {{ impostos?.total_pis?.porcentagem }}
                    </span>
                    P.A.
                </p>
                <VTooltipVue size="0.8rem" title="PIS:" :description="impostos?.total_pis?.periodo_anterior" :dateStart="dataInicioApiFiltro" :dateEnd="dataFimApiFiltro" v-show="!impostoPlaceholderLoading"></VTooltipVue>
            </div>
        </v-card>

        <v-card class="span-2 flex fd-c fgap1" :class="{ 'placeholder-set': impostoPlaceholderLoading }" shadow>
            <h4 :class="{ 'placeholder-loading plw-50 plh-20': impostoPlaceholderLoading }">
                <span class="bold"> COFINS </span>
                <span class="text-sm">Total</span>
            </h4>
            <p class="text-bg bold text-wordbreak" :class="{ 'placeholder-loading plw-100 plh-30': impostoPlaceholderLoading }">
                {{ impostos?.total_confins?.periodo_atual }}
            </p>
            <div class="flex ai-c" :class="{ 'placeholder-loading plw-100 plh-20': impostoPlaceholderLoading }">
                <move-up-icon size="16" class="text-success" v-if="impostos?.total_confins?.variacao == 'UP'"/>
                <move-down-icon size="16" class="text-danger" v-else-if="impostos?.total_confins?.variacao == 'DOWN'"/>
                <p>
                    <span class="text-medium" :class="impostos?.total_confins?.variacao == 'UP' ? 'text-success' : 'text-danger'">
                        {{ impostos?.total_confins?.porcentagem }}
                    </span>
                    P.A.
                </p>
                <VTooltipVue size="0.8rem" title="COFINS:" :description="impostos?.total_confins?.periodo_anterior" :dateStart="dataInicioApiFiltro" :dateEnd="dataFimApiFiltro" v-show="!impostoPlaceholderLoading"></VTooltipVue>
            </div>
        </v-card>

        <v-card class="span-2 flex fd-c fgap1" :class="{ 'placeholder-set': impostoPlaceholderLoading }" shadow>
            <h4 :class="{ 'placeholder-loading plw-50 plh-20': impostoPlaceholderLoading }">
                <span class="bold"> SICOMEX </span>
                <span class="text-sm">Total</span>
            </h4>
            <p class="text-bg bold text-wordbreak" :class="{ 'placeholder-loading plw-100 plh-30': impostoPlaceholderLoading }">
                {{ impostos?.total_siscomex?.periodo_atual }}
            </p>
            <div class="flex ai-c" :class="{ 'placeholder-loading plw-100 plh-20': impostoPlaceholderLoading }">
                <move-up-icon size="16" class="text-success" v-if="impostos?.total_siscomex?.variacao == 'UP'"/>
                <move-down-icon size="16" class="text-danger" v-else-if="impostos?.total_siscomex?.variacao == 'DOWN'"/>
                <p>
                    <span class="text-medium" :class="impostos?.total_siscomex?.variacao == 'UP' ? 'text-success' : 'text-danger'">
                        {{ impostos?.total_siscomex?.porcentagem }}
                    </span>
                    P.A.
                </p>
                <VTooltipVue size="0.8rem" title="SICOMEX:" :description="impostos?.total_siscomex?.periodo_anterior" :dateStart="dataInicioApiFiltro" :dateEnd="dataFimApiFiltro" v-show="!impostoPlaceholderLoading"></VTooltipVue>
            </div>
        </v-card>

        <v-card class="span-2 flex fd-c fgap1" :class="{ 'placeholder-set': impostoPlaceholderLoading }" shadow>
            <h4 :class="{ 'placeholder-loading plw-50 plh-20': impostoPlaceholderLoading }">
                <span class="bold"> ANTIDUMPING </span>
                <span class="text-sm">Total</span>
            </h4>
            <p class="text-bg bold text-wordbreak" :class="{ 'placeholder-loading plw-100 plh-30': impostoPlaceholderLoading }">
                {{ impostos?.total_antidumping?.periodo_atual }}
            </p>
            <div class="flex ai-c" :class="{ 'placeholder-loading plw-100 plh-20': impostoPlaceholderLoading }">
                <move-up-icon size="16" class="text-success" v-if="impostos?.total_antidumping?.variacao == 'UP'"/>
                <move-down-icon size="16" class="text-danger" v-else-if="impostos?.total_antidumping?.variacao == 'DOWN'"/>
                <p>
                    <span class="text-medium" :class="impostos?.total_antidumping?.variacao == 'UP' ? 'text-success' : 'text-danger'">
                        {{ impostos?.total_antidumping?.porcentagem }}
                    </span>
                    P.A.
                </p>
                <VTooltipVue size="0.8rem" title="ANTIDUMPING:" :description="impostos?.total_antidumping?.periodo_anterior" :dateStart="dataInicioApiFiltro" :dateEnd="dataFimApiFiltro" v-show="!impostoPlaceholderLoading"></VTooltipVue>
            </div>
        </v-card>

        <v-card noPadding class="span-12">
            <v-large-bar-char
                :series="processosPorPeriodoMeses"
                v-if="processosPorPeriodoMeses.length > 0"
                v-show="processosPorPeriodoTipo == 'meses' && !processosPorPeriodoMesesLoading"
            />

            <div class="span-9" v-show="processosPorPeriodoTipo == 'meses' && processosPorPeriodoMesesLoading">
                <div class="flex1 mb-2 placeholder-set">
                    <div class="p-2 flex ai-c jc-sb fgap1">
                        <p class="placeholder-loading plw-20 plh-30"></p>
                    </div>
                </div>
                <div class="flex">
                    <ul class="pv-2 ph-2 flex fgap2 w-full" v-for="i in 12" :key="`i-${i}`">
                        <div class="flex fd-c fgap1 text-center" style="flex: 0 1 4.7619%; min-height: 300px;">
                            <div class="bar-loading" style="width: 50px">
                                <div class="level" style="--percentage: 99.2%;"></div>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
            <!-- <v-bar-chart minHeight="300px" :series="processosPorPeriodoDias" v-if="processosPorPeriodoDias.length > 0" /> -->
            <hr class="light m-0" />

            <div class="p-2 flex fd-c fgap2">
                <v-card theme="light"  class="flex fgap2 fw-w">
                    <div class="flex1 flex fd-c jc-c" :class="{ 'placeholder-set': processosPorModalLoading }">
                        <div class="flex ai-c fgap1 mb-1" :class="{ 'placeholder-loading plw-50 plh-20': processosPorModalLoading }">
                            <p>Total de Processos</p>
                        </div>

                        <p class="mb-1 text-md" :class="{ 'placeholder-loading plw-100 plh-30': processosPorModalLoading }">
                            <span class="text-bold">
                                {{ processosPorModal?.total_processos?.periodo_atual }}
                            </span>
                            <span class="ml-1">Processos</span>
                        </p>

                        <div class="flex ai-fs" :class="{ 'placeholder-loading plw-100 plh-30': processosPorModalLoading }">
                            <move-up-icon size="24" style="margin-top: 5px" class="text-success" v-if="processosPorModal?.total_processos?.variacao == 'UP'" />
                            <move-down-icon size="24" style="margin-top: 5px" class="text-danger" v-else-if="processosPorModal?.total_processos?.variacao == 'DOWN'"/>
                            <div>
                                <p class="text-light">
                                    <span class="text-medium" :class="processosPorModal?.total_processos?.variacao == 'UP' ? 'text-success' : 'text-danger'">
                                        {{ processosPorModal?.total_processos?.porcentagem }}
                                    </span>
                                </p>
                                <div class="flex ai-gc">
                                    <span class="text-sm">{{ processosPorModal?.total_processos?.variacao | textPA }}</span>
                                    <VTooltipVue
                                        size="0.8rem"
                                        description="Processos"
                                        :title="`${processosPorModal?.total_processos?.periodo_anterior.toString()}`"
                                        :dateStart="dataInicioApiFiltro"
                                        :dateEnd="dataFimApiFiltro"
                                        v-show="!processosPorModalLoading"
                                    ></VTooltipVue>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="lined-separator hide-mobile"></div>

                    <div class="flex1 flex fd-c jc-c ai-gc" v-show="!processosPorModalLoading">
                        <v-donut-chart
                            v-show="processosPorModal?.total_maritimo?.periodo_atual !== 0 || processosPorModal?.total_aereo?.periodo_atual !== 0 || processosPorModal?.total_rodoviario?.periodo_atual !== 0"
                            chartWidth="100%"
                            donutWidth="50%"
                            maxWidth="250px"
                            minWidth="200px"
                            :series="[
                                processosPorModal?.total_maritimo?.periodo_atual,
                                processosPorModal?.total_aereo?.periodo_atual,
                                processosPorModal?.total_rodoviario?.periodo_atual,
                            ]"
                            :labels="['Marítimo', 'Áereo', 'Rodoviário']"
                        />
                        <v-donut-chart
                            v-show="processosPorModal?.total_maritimo?.periodo_atual === 0 && processosPorModal?.total_aereo?.periodo_atual === 0 && processosPorModal?.total_rodoviario?.periodo_atual === 0"
                            chartWidth="100%"
                            donutWidth="70%"
                            :series="[ 100 ]"
                            :colors="['#104375']"
                            :labelFormatter="() => 'Sem Registros'"
                        />
                    </div>

                    <div class="flex1 flex ai-c jc-c" v-show="processosPorModalLoading">
                        <div style="max-width: 170px; aspect-ratio: 1/1;"  :class="{ 'circle-loading': processosPorModalLoading }"></div>
                    </div>

                    <div class="lined-separator hide-mobile"></div>

                    <div class="flex1 flex fd-c jc-c" :class="{ 'placeholder-set': processosPorModalLoading }">
                        <div class="flex ai-c fgap1 mb-1" :class="{ 'placeholder-loading plw-50 plh-20': processosPorModalLoading }">
                            <p>Marítimo</p>
                        </div>

                        <p class="mb-1 text-md" :class="{ 'placeholder-loading plw-100 plh-30': processosPorModalLoading }">
                            <span class="text-bold">
                                {{ processosPorModal?.total_maritimo?.periodo_atual }}
                            </span>
                            <span class="ml-1">Processos</span>
                        </p>

                        <div class="flex ai-fs" :class="{ 'placeholder-loading plw-100 plh-20': processosPorModalLoading }">
                            <move-up-icon size="24" style="margin-top: 5px" class="text-success" v-if="processosPorModal?.total_maritimo?.variacao == 'UP'"/>
                            <move-down-icon size="24" style="margin-top: 5px" class="text-danger" v-else-if="processosPorModal?.total_maritimo?.variacao == 'DOWN'"/>
                            <div>
                                <p class="text-light">
                                    <span class="text-medium" :class="processosPorModal?.total_maritimo?.variacao == 'UP' ? 'text-success' : 'text-danger'">
                                    {{ processosPorModal?.total_maritimo?.porcentagem }}
                                    </span>
                                </p>
                                <div class="flex ai-gc">
                                    <span class="text-sm">{{ processosPorModal?.total_maritimo?.variacao | textPA }}</span>
                                    <VTooltipVue
                                        size="0.8rem"
                                        description="Processos"
                                        :title="`${processosPorModal?.total_maritimo?.periodo_anterior.toString()}`"
                                        :dateStart="dataInicioApiFiltro"
                                        :dateEnd="dataFimApiFiltro"
                                        v-show="!processosPorModalLoading"
                                    ></VTooltipVue>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex1 flex fd-c jc-c" :class="{ 'placeholder-set': processosPorModalLoading }">
                        <div class="flex ai-c fgap1 mb-1" :class="{ 'placeholder-loading plw-50 plh-20': processosPorModalLoading }">
                            <p>Áereo</p>
                        </div>

                        <p class="mb-1 text-md" :class="{ 'placeholder-loading plw-100 plh-30': processosPorModalLoading }">
                            <span class="text-bold">
                                {{ processosPorModal?.total_aereo?.periodo_atual }}
                            </span>
                            <span class="ml-1">Processos</span>
                        </p>

                        <div class="flex ai-fs" :class="{ 'placeholder-loading plw-100 plh-20': processosPorModalLoading }">
                            <move-up-icon size="24" style="margin-top: 5px" class="text-success" v-if="processosPorModal?.total_aereo?.variacao == 'UP'"/>
                            <move-down-icon size="24" style="margin-top: 5px" class="text-danger" v-else-if="processosPorModal?.total_aereo?.variacao == 'DOWN'"/>
                            <div>
                                <p class="text-light flex">
                                    <span class="text-medium" :class="processosPorModal?.total_aereo?.variacao == 'UP'? 'text-success' : 'text-danger'">
                                    {{ processosPorModal?.total_aereo?.porcentagem }}
                                    </span>
                                </p>
                                <div class="flex ai-gc">
                                    <span class="text-sm">{{ processosPorModal?.total_aereo?.variacao | textPA }}</span>
                                    <VTooltipVue size="0.8rem" description="Processos" :title="`${processosPorModal?.total_aereo?.periodo_anterior.toString()}`" :dateStart="dataInicioApiFiltro" :dateEnd="dataFimApiFiltro" v-show="!processosPorModalLoading"></VTooltipVue>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="flex1 flex fd-c jc-c" :class="{ 'placeholder-set': processosPorModalLoading }">
                        <div class="flex ai-c fgap1 mb-1" :class="{ 'placeholder-loading plw-50 plh-20': processosPorModalLoading }">
                            <p>Rodoviário</p>
                        </div>

                        <p class="mb-1 text-md" :class="{ 'placeholder-loading plw-100 plh-30': processosPorModalLoading }">
                            <span class="text-bold">
                                {{ processosPorModal?.total_rodoviario?.periodo_atual }}
                            </span>
                            <span class="ml-1">Processos</span>
                        </p>

                        <div class="flex ai-fs" :class="{ 'placeholder-loading plw-100 plh-20': processosPorModalLoading }">
                            <move-up-icon size="24" style="margin-top: 5px" class="text-success" v-if="processosPorModal?.total_rodoviario?.variacao == 'UP'" />
                            <move-down-icon size="24" style="margin-top: 5px" class="text-danger" v-else-if=" processosPorModal?.total_rodoviario?.variacao == 'DOWN'"
                            />
                            <div>
                                <p class="text-light flex">
                                    <span class="text-medium" :class="processosPorModal?.total_rodoviario?.variacao == 'UP' ? 'text-success' : 'text-danger'">
                                    {{ processosPorModal?.total_rodoviario?.porcentagem }}
                                    </span>
                                </p>
                                <div class="flex ai-gc">
                                    <span class="text-sm">{{ processosPorModal?.total_rodoviario?.variacao | textPA }}</span>
                                    <VTooltipVue size="0.8rem" description="Processos" :title="`${processosPorModal?.total_rodoviario?.periodo_anterior.toString()}`" :dateStart="dataInicioApiFiltro" :dateEnd="dataFimApiFiltro" v-show="!processosPorModalLoading"></VTooltipVue>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>

                <hr class="light m-0" v-show="!processosPorPeriodoDiasLoading && processosPorPeriodoTipo == 'dias'" />

                <v-large-bar-char
                    :series="processosPorPeriodoDias"
                    v-if="processosPorPeriodoDias.length > 0"
                    modifyClass="modify"
                    v-show="!processosPorPeriodoDiasLoading && processosPorPeriodoTipo == 'dias'"
                />

                <div class="span-9" v-show="processosPorPeriodoTipo == 'dias' && processosPorPeriodoDiasLoading">
                    <div class="flex1 mb-2 placeholder-set">
                        <div class="p-2 flex ai-c jc-sb fgap1">
                            <p class="placeholder-loading plw-20 plh-30"></p>
                        </div>
                    </div>
                    <div class="flex">
                        <ul class="pv-2 ph-2 flex fgap2 w-full" v-for="i in 20" :key="`i-${i}`">
                            <div class="flex fd-c fgap1 text-center" style="flex: 0 1 4.7619%; min-height: 300px;">
                                <div class="bar-loading" style="width: 25px">
                                    <div class="level" style="--percentage: 99.2%;"></div>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>

            <hr class="light m-0" />

            <div class="p-2 flex fd-c fgap2">
                <v-card class="flex fgap2 fw-w">
                    <div class="flex1" :class="{ 'placeholder-set': valoresTotalVLMDeICMSLoading }">
                        <div class="flex ai-c fgap1 mb-1" :class="{ 'placeholder-loading plw-70 plh-20': valoresTotalVLMDeICMSLoading }">
                            <p>Valor Total VLMD</p>
                            <v-badge theme="success" text="Sem Impostos" />
                        </div>

                        <p class="mb-1 text-lg text-bold" :class="{ 'placeholder-loading plw-100 plh-30': valoresTotalVLMDeICMSLoading }">
                            {{ this.valoresTotalVLMDeICMS?.vmld?.total_sem_impostos ? this.valoresTotalVLMDeICMS?.vmld?.total_sem_impostos : 0 | moneyBRL }}
                        </p>

                        <div class="flex" :class="{ 'fd-c': valoresTotalVLMDeICMSLoading, 'ai-fs': !valoresTotalVLMDeICMSLoading }">
                            <move-up-icon size="24" style="margin-top: 5px" class="text-success" v-if="valoresTotalVLMDeICMS?.variacao_sem_impostos == 'UP'" />
                            <move-down-icon size="24" style="margin-top: 5px" class="text-danger" v-else-if="valoresTotalVLMDeICMS?.variacao_sem_impostos == 'DOWN'"/>
                            <div :class="{ 'placeholder-set': valoresTotalVLMDeICMSLoading }">
                                <p class="text-light flex" :class="{ 'placeholder-loading plw-100 plh-16': valoresTotalVLMDeICMSLoading }">
                                    <span class="text-medium" style="margin-right: 5px" :class="valoresTotalVLMDeICMS?.variacao_sem_impostos == 'UP' ? 'text-success' : 'text-danger'">
                                        {{ valoresTotalVLMDeICMS?.porcentagem_sem_impostos ?? 0 }}%
                                    </span>
                                    {{ valoresTotalVLMDeICMS?.variacao_sem_impostos | textPA }}
                                    <VTooltipVue
                                        size="0.8rem"
                                        :description="`${new Intl.NumberFormat('pt-BR',).format(valoresTotalVLMDeICMS?.vmld_periodo_anterior?.total_sem_impostos ?? 0)}`"
                                        :dateStart="dataInicioApiFiltro"
                                        :dateEnd="dataFimApiFiltro"
                                        v-show="!valoresTotalVLMDeICMSLoading"
                                    ></VTooltipVue>
                                </p>
                                <p class="text-sm" :class="{ 'placeholder-loading plw-100 plh-16': valoresTotalVLMDeICMSLoading }">P.A. é a abreviação de período anterior</p>
                            </div>
                        </div>
                    </div>

                    <div class="lined-separator hide-mobile"></div>

                    <div class="flex1" :class="{ 'placeholder-set': valoresTotalVLMDeICMSLoading }">
                        <div class="flex ai-c fgap1 mb-1" :class="{ 'placeholder-loading plw-70 plh-20': valoresTotalVLMDeICMSLoading }">
                            <p>Valor Total VMLD</p>
                            <v-badge theme="danger" text="Com Impostos" />
                        </div>
                        <p class="mb-1 text-lg text-bold" :class="{ 'placeholder-loading plw-100 plh-30': valoresTotalVLMDeICMSLoading }">
                            {{ this.valoresTotalVLMDeICMS?.vmld?.total_com_impostos ? this.valoresTotalVLMDeICMS?.vmld?.total_com_impostos : 0 | moneyBRL }}
                        </p>
                        <div class="flex" :class="{ 'fd-c': valoresTotalVLMDeICMSLoading, 'ai-fs': !valoresTotalVLMDeICMSLoading }">
                            <move-up-icon size="24" style="margin-top: 5px" class="text-success" v-if="valoresTotalVLMDeICMS?.variacao_com_impostos == 'UP'" />
                            <move-down-icon size="24" style="margin-top: 5px" class="text-danger" v-else-if="valoresTotalVLMDeICMS?.variacao_com_impostos == 'DOWN'"/>
                            <div :class="{ 'placeholder-set': valoresTotalVLMDeICMSLoading }">
                                <p class="text-light flex" :class="{ 'placeholder-loading plw-100 plh-16': valoresTotalVLMDeICMSLoading }">
                                    <span class="text-medium" style="margin-right: 5px"  :class="valoresTotalVLMDeICMS?.variacao_com_impostos == 'UP' ? 'text-success' : 'text-danger'">
                                        {{ valoresTotalVLMDeICMS?.porcentagem_com_impostos ?? 0 }}%
                                    </span>
                                    {{ valoresTotalVLMDeICMS?.variacao_com_impostos | textPA }}
                                    <VTooltipVue
                                        size="0.8rem"
                                        :description="`${new Intl.NumberFormat('pt-BR',).format(valoresTotalVLMDeICMS?.vmld_periodo_anterior?.total_com_impostos ?? 0)}`"
                                        :dateStart="dataInicioApiFiltro"
                                        :dateEnd="dataFimApiFiltro"
                                        v-show="!valoresTotalVLMDeICMSLoading"
                                    ></VTooltipVue>
                                </p>
                                <p class="text-sm" :class="{ 'placeholder-loading plw-100 plh-16': valoresTotalVLMDeICMSLoading }">P.A. é a abreviação de período anterior</p>
                            </div>
                        </div>
                    </div>

                    <div class="lined-separator hide-mobile"></div>

                    <div class="flex1" :class="{ 'placeholder-set': pesosImportadosLoading }">
                        <div class="flex ai-c fgap1 mb-1" :class="{ 'placeholder-loading plw-70 plh-20': pesosImportadosLoading }">
                            <p>Peso Bruto</p>
                        </div>
                        <p class="mb-1 text-lg text-bold" :class="{ 'placeholder-loading plw-100 plh-30': pesosImportadosLoading }">
                            {{ pesosImportados?.peso_bruto?.periodo_atual }} KG
                        </p>
                        <div class="flex" :class="{ 'fd-c': pesosImportadosLoading, 'ai-fs': !pesosImportadosLoading }">
                            <move-up-icon size="24" style="margin-top: 5px" class="text-success" v-if="pesosImportados?.peso_bruto?.variacao == 'UP'" />
                            <move-down-icon size="24" style="margin-top: 5px" class="text-danger" v-else-if="pesosImportados?.peso_bruto?.variacao == 'DOWN'"/>
                            <div :class="{ 'placeholder-set': pesosImportadosLoading }">
                                <p class="text-light flex" :class="{ 'placeholder-loading plw-100 plh-16': pesosImportadosLoading }">
                                    <span class="text-medium" style="margin-right: 5px"  :class="pesosImportados?.peso_bruto?.variacao == 'UP' ? 'text-success' : 'text-danger'">
                                        {{ pesosImportados?.peso_bruto?.porcentagem }}
                                    </span>
                                    {{ pesosImportados?.peso_bruto?.variacao | textPA }}
                                    <VTooltipVue
                                        size="0.8rem"
                                        :description="`${ pesosImportados?.peso_bruto?.periodo_anterior } KG`"
                                        :dateStart="dataInicioApiFiltro"
                                        :dateEnd="dataFimApiFiltro"
                                        v-show="!pesosImportadosLoading"
                                    ></VTooltipVue>
                                </p>
                                <p class="text-sm" :class="{ 'placeholder-loading plw-100 plh-16': pesosImportadosLoading }">P.A. é a abreviação de período anterior</p>
                            </div>
                        </div>
                    </div>

                    <div class="lined-separator hide-mobile"></div>

                    <div class="flex1" :class="{ 'placeholder-set': pesosImportadosLoading }">
                        <div class="flex ai-c fgap1 mb-1" :class="{ 'placeholder-loading plw-70 plh-20': pesosImportadosLoading }">
                            <p>Peso Líquido</p>
                        </div>
                        <p class="mb-1 text-lg text-bold" :class="{ 'placeholder-loading plw-100 plh-30': pesosImportadosLoading }">
                            {{ pesosImportados?.peso_liquido?.periodo_atual }} KG
                        </p>
                        <div class="flex" :class="{ 'fd-c': pesosImportadosLoading, 'ai-fs': !pesosImportadosLoading }">
                            <move-up-icon size="24" style="margin-top: 5px" class="text-success" v-if="pesosImportados?.peso_liquido?.variacao == 'UP'" />
                            <move-down-icon size="24" style="margin-top: 5px" class="text-danger" v-else-if="pesosImportados?.peso_liquido?.variacao == 'DOWN'"/>
                            <div :class="{ 'placeholder-set': pesosImportadosLoading }">
                                <p class="text-light flex" :class="{ 'placeholder-loading plw-100 plh-16': pesosImportadosLoading }">
                                    <span class="text-medium" style="margin-right: 5px"  :class="pesosImportados?.peso_liquido?.variacao == 'UP' ? 'text-success' : 'text-danger'">
                                        {{ pesosImportados?.peso_liquido?.porcentagem }}
                                    </span>
                                    {{ pesosImportados?.peso_liquido?.variacao | textPA }}
                                    <VTooltipVue
                                        size="0.8rem"
                                        :description="`${ pesosImportados?.peso_liquido?.periodo_anterior } KG`"
                                        :dateStart="dataInicioApiFiltro"
                                        :dateEnd="dataFimApiFiltro"
                                        v-show="!pesosImportadosLoading"
                                    ></VTooltipVue>
                                </p>
                                <p class="text-sm" :class="{ 'placeholder-loading plw-100 plh-16': pesosImportadosLoading }">P.A. é a abreviação de período anterior</p>
                            </div>
                        </div>
                    </div>
                </v-card>
            </div>

            <hr class="light m-0" />

            <div class="columns gap2 p-2">
                <v-card theme="light" class="span-3" style="min-width: 376px">
                    <div class="flex1 mb-2" :class="{ 'placeholder-set': valorFretesLoading }">
                        <div class="flex ai-c fgap1" :class="{ 'placeholder-loading plw-50 plh-20': valorFretesLoading }">
                            <p>Valor Total em Frete</p>
                        </div>

                        <p class="mb-1 text-lg text-bold" :class="{ 'placeholder-loading plw-100 plh-30': valorFretesLoading }">
                            {{ valorFretes?.total_periodo_atual ?? 0 | moneyBRL }}
                        </p>

                        <div class="flex" :class="{ 'fd-c': valorFretesLoading, 'ai-fs': !valorFretesLoading }">
                            <move-up-icon size="24" style="margin-top: 5px" class="text-success" v-if="valorFretes?.variacao == 'UP'" />
                            <move-down-icon size="24" style="margin-top: 5px" class="text-danger" v-else-if="valorFretes?.variacao == 'DOWN'"/>
                            <div :class="{ 'placeholder-set': valorFretesLoading }">
                                <p class="text-light flex" :class="{ 'placeholder-loading plw-100 plh-16': valorFretesLoading }">
                                    <span class="text-medium" style="margin-right: 5px" :class="valorFretes?.variacao == 'UP' ? 'text-success' : 'text-danger'">
                                        {{ valorFretes?.porcentagem ?? 0 }}%
                                    </span>
                                    {{ valorFretes?.variacao | textPA }}
                                    <VTooltipVue
                                        size="0.8rem"
                                        title="Valor Total em Frete: "
                                        :description="`${new Intl.NumberFormat('pt-BR',).format(valorFretes?.total_periodo_anterior ?? 0)}`"
                                        :dateStart="dataInicioApiFiltro"
                                        :dateEnd="dataFimApiFiltro"
                                        v-show="!valorFretesLoading"
                                    ></VTooltipVue>
                                </p>
                                <p class="text-sm" :class="{ 'placeholder-loading plw-100 plh-16': valorFretesLoading }">P.A. é a abreviação de período anterior</p>
                            </div>
                        </div>
                    </div>

                    <div style="max-width: 400px" v-show="!valorFretesLoading">
                        <v-donut-chart
                            v-show="valoresTotalFretes?.series.length > 0"
                            chartWidth="100%"
                            donutWidth="70%"
                            :labels="valoresTotalFretes?.labels"
                            :series="valoresTotalFretes?.series.map(Number)"
                            :label-formatter="vDonutChartFrete"
                        />
                        <v-donut-chart
                            v-show="valoresTotalFretes?.series.length === 0"
                            chartWidth="100%"
                            donutWidth="70%"
                            :series="[ 100 ]"
                            :labelFormatter="() => 'Sem Frete'"
                        />
                    </div>
                    <div class="flex ai-c jc-c" v-show="valorFretesLoading">
                        <div style="max-width: 300px; aspect-ratio: 1/1;" :class="{ 'circle-loading': valorFretesLoading }"></div>
                    </div>
                </v-card>

                <v-card bordered class="span-9 columns gap2">
                    <div class="span-9" v-show="!mediasTaxaCambioLoading">
                        <div class="flex1 mb-2">
                            <div class="flex ai-c fgap1">
                                <p>Taxa Média de Câmbio</p>
                            </div>
                            <p class="text-lg text-bold" v-for="(item, index) in mediasTaxaCambioPrincial" :key="`uniq-${item.id}-${index}`">
                                {{ moneyLabelList[index].symbol }}
                                {{ item?.media_periodo?.replace(".", ",") }}
                                {{ moneyLabelList[index].label }}
                            </p>
                        </div>
                        <div>
                            <v-bar-chart minHeight="400px" :series="this.mediasTaxaCambioPeriodos"/>
                        </div>
                    </div>

                    <div class="span-3" v-show="!mediasTaxaCambioLoading">
                        <p class="text-md mb-2">Outras Médias</p>

                        <div class="flex fd-c fgap1">
                            <v-card theme="light" class="flex ai-c fgap1 jc-sb" v-for="(item, index) in mediasTaxaCambio" :key="`${item.id}-${index}`" @clickCard="organizaDadosDaMediasTaxaCambio(index)" :mousePointer="true">
                                <div>
                                    <p class="text-semibold">
                                        {{ moneyLabelList[index].name }}
                                    </p>
                                    <p v-if="item?.periodos">{{ periodRange }}</p>
                                    <p v-if="item?.cotacoes">{{ periodRangeDays }}</p>
                                </div>
                                <div>
                                    <p class="bold text-lg">
                                        {{ item?.media_periodo?.replace(".", ",") }}
                                    </p>
                                </div>
                            </v-card>
                        </div>
                    </div>

                    <div class="span-9" v-show="mediasTaxaCambioLoading">
                        <div class="flex1 mb-2 placeholder-set">
                            <div class="flex ai-c">
                                <p class="placeholder-loading plw-30 plh-20"></p>
                            </div>
                            <p class="placeholder-loading plw-30 plh-40"></p>
                        </div>
                        <div class="flex">
                            <ul class="pv-2 ph-2 flex fgap2 w-full" v-for="i in 12" :key="`i-${i}`">
                                <div class="flex fd-c fgap1 text-center" style="flex: 0 1 4.7619%; min-height: 400px;">
                                    <div class="bar-loading">
                                        <div class="level" style="--percentage: 99.2%;"></div>
                                    </div>
                                </div>
                            </ul>
                        </div>
                    </div>

                    <div class="span-3 placeholder-set" v-show="mediasTaxaCambioLoading">
                        <p class="mb-2 placeholder-loading plw-50 plh-40"></p>

                        <div class="flex fd-c fgap1 placeholder-set">
                            <v-card theme="light" class="flex ai-c fgap1 jc-sb" v-for="m in 4" :key="`m-${m}`">
                                <div class="placeholder-set" style="width: 100%">
                                    <p class="placeholder-loading plw-100 plh-20"></p>
                                    <p class="placeholder-loading plw-100 plh-30"></p>
                                </div>
                                <div class="placeholder-set" style="width: 100%">
                                    <p class="placeholder-loading plw-100 plh-50"></p>
                                </div>
                            </v-card>
                        </div>
                    </div>
                </v-card>
            </div>

            <hr class="light m-0" />

            <div class="columns gap2 p-2">
                <v-card theme="light" class="span-3" style="min-width: 376px">
                    <div class="flex1 mb-2" :class="{ 'placeholder-set': processosPorCanalLoading }">
                        <div class="flex ai-c fgap1" :class="{ 'placeholder-loading plw-50 plh-20': processosPorCanalLoading }">
                            <p>Percentual de Canais de DIs</p>
                        </div>

                        <p class="mb-1 text-lg text-bold" :class="{ 'placeholder-loading plw-100 plh-30': processosPorCanalLoading }">
                            {{ processosPorCanal.total_processos }} <span class="text-md text-normal">Registros</span>
                        </p>
                    </div>

                    <div style="max-width: 400px" class="mb-2" v-show="!processosPorCanalLoading">
                        <v-donut-chart
                            v-show="processosPorCanal.total_amarelo !== 0 || processosPorCanal.total_verde !== 0 || processosPorCanal.total_vermelho !== 0"
                            chartWidth="100%"
                            donutWidth="70%"
                            :labels="['Amarelo', 'Verde', 'Vermelho']"
                            :series="[
                                processosPorCanal.total_amarelo,
                                processosPorCanal.total_verde,
                                processosPorCanal.total_vermelho,
                            ]"
                            :colors="['#DBB058', '#137700', '#F26767']"
                        />
                        <v-donut-chart
                            v-show="processosPorCanal.total_amarelo == 0 && processosPorCanal.total_verde == 0 && processosPorCanal.total_vermelho == 0"
                            chartWidth="100%"
                            donutWidth="70%"
                            :series="[ 100 ]"
                            :colors="['#104375']"
                            :labelFormatter="() => 'Sem Registros'"
                        />
                    </div>
                    <div class="flex ai-c jc-c mb-2" v-show="processosPorCanalLoading">
                        <div style="max-width: 300px; aspect-ratio: 1/1;" :class="{ 'circle-loading': processosPorCanalLoading }"></div>
                    </div>
                </v-card>

                <v-card class="span-9 flex fd-c jc-sb" v-show="!leadTimesDadosLoading">
                    <p class="mb-2">Lead Times</p>
                    <div>
                        <v-horizontal-timeline :items="this.leadTimesDados" />
                        <div class="flex fw-w fgap2 mt-2">
                            <a
                                href="#"
                                @click.prevent="buscarLeadTimes(item.value)"
                                class="btn-analytics"
                                :class="(modalButtonSelect == item.value || ( modalButtonSelect == '' && filters.modal == item.value)) ? 'selected' : ''"
                                v-for="(item, index) in modalListButton"
                                :key="index"
                            >
                                Ver {{ item.label | labelFormat }}
                            </a>
                        </div>
                    </div>
                    <div></div>
                </v-card>
                <v-card class="span-9 flex fd-c jc-sb white" v-show="leadTimesDadosLoading">
                    <p class="mb-2 placeholder-loading plw-10 plh-20"></p>
                    <div>
                        <div class="flex fd-c" style="gap: 1rem;">
                            <div class="flex">
                                <div class="flex jc-c" style="width: 33.333333333333336%;">
                                    <div class="placeholder-loading plw-30 plh-30"></div>
                                </div>
                                <div class="flex jc-c" style="width: 33.333333333333336%;">
                                    <div class="placeholder-loading plw-30 plh-30"></div>
                                </div>
                                <div class="flex jc-c" style="width: 33.333333333333336%;">
                                    <div class="placeholder-loading plw-30 plh-30"></div>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="flex jc-c" style="width: 33.333333333333336%;">
                                    <div class="placeholder-loading plw-60 plh-40"></div>
                                </div>
                                <div class="flex jc-c" style="width: 33.333333333333336%;">
                                    <div class="placeholder-loading plw-60 plh-40"></div>
                                </div>
                                <div class="flex jc-c" style="width: 33.333333333333336%;">
                                    <div class="placeholder-loading plw-60 plh-40"></div>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="flex fd-c jc-c ai-gc gap1" style="width: 33.333333333333336%;">
                                    <div class="placeholder-loading plw-40 plh-20"></div>
                                    <div class="placeholder-loading plw-60 plh-20"></div>
                                </div>
                                <div class="flex fd-c jc-c ai-gc gap1" style="width: 33.333333333333336%;">
                                    <div class="placeholder-loading plw-40 plh-20"></div>
                                    <div class="placeholder-loading plw-60 plh-20"></div>
                                </div>
                                <div class="flex fd-c jc-c ai-gc gap1" style="width: 33.333333333333336%;">
                                    <div class="placeholder-loading plw-40 plh-20"></div>
                                    <div class="placeholder-loading plw-60 plh-20"></div>
                                </div>
                            </div>
                            <div class="flex gap1">
                                <div class="placeholder-loading plw-50 plh-40" style="width: 33.333333333333336%;"></div>
                                <div class="placeholder-loading plw-50 plh-40" style="width: 33.333333333333336%;"></div>
                                <div class="placeholder-loading plw-50 plh-40" style="width: 33.333333333333336%;"></div>
                                <div class="placeholder-loading plw-50 plh-40" style="width: 33.333333333333336%;"></div>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </v-card>
            </div>
        </v-card>

        <v-card class="span-12">
            <div class="columns gap2">
                <div class="span-3" style="min-width: 490px;" v-show="!mapaDadosLoading">
                    <p class="text-lg bold mb-2">Filtrar Por</p>

                    <div class="flex fd-c fgap2 mb-4">
                        <v-input-taggable
                            label="País"
                            :options="mapaPaisesOrigemFiltro"
                            v-model="pais"
                            name="searchCountry"
                            placeholder="Digite o nome do País"
                            @input="filtarMapaPaisesOrigem($event)"
                        />
                        <v-input-taggable
                            label="NCM"
                            :options="mapaNcmFiltro"
                            v-model="ncm"
                            name="searchNcm"
                            placeholder="Digite o código NCM"
                            @input="filtarMapaNcm($event)"
                        />
                    </div>

                    <p>NCM mais importado</p>
                    <p class="text-lg bold mb-2">{{ mapaDados.topNcm | ncmFormat}}</p>

                    <v-card theme="light">
                        <div class="columns gap1">
                            <div class="span-7">
                                <div class="flex fgap1">
                                    <award-icon style="margin-top: 0.4rem; flex: 0 0 1.5rem" />
                                    <div class="flex1">
                                        <p class="text-light text-md">País de Origem</p>
                                        <p class="text-bg bold mb-2">{{ mapaDados.topPais }}</p>
                                    </div>
                                </div>

                                <div class="flex fgap1">
                                    <goal-icon style="margin-top: 0.4rem; flex: 0 0 1.5rem" />
                                    <div class="flex1">
                                        <p class="text-light text-md">Total c/ Impostos</p>
                                        <p class="text-bg bold mb-2">{{ mapaDados.totalImpostos }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="span-5">
                                <v-gauge :maxValue="processosPorModal?.total_processos?.periodo_atual" :stopValue="mapaDados?.totalProcessos" width="100%" label="Total de processos"/>
                            </div>
                        </div>
                    </v-card>
                </div>

                <div class="span-3 placeholder-set" style="min-width: 490px;" v-show="mapaDadosLoading">
                    <p class="placeholder-loading plw-40 plh-40 mb-2"></p>

                    <div class="flex fd-c fgap2 mb-2">
                        <div class="placeholder-loading plw-20 plh-20"></div>
                        <div class="placeholder-loading plw-100 plh-40"></div>
                        <div class="placeholder-loading plw-20 plh-20"></div>
                        <div class="placeholder-loading plw-100 plh-40"></div>
                    </div>

                    <p class="placeholder-loading plw-20 plh-20"></p>
                    <p class="mb-1 placeholder-loading plw-50 plh-40"></p>

                    <v-card theme="light">
                        <div class="flex fd-c fgap2">
                            <div class="placeholder-set mb-2 mt-1">
                                <p class="placeholder-loading plw-100 plh-20"></p>
                                <p class="placeholder-loading plw-100 plh-30"></p>
                            </div>
                            <div class="placeholder-set">
                                <p class="placeholder-loading plw-100 plh-20"></p>
                                <p class="placeholder-loading plw-100 plh-30"></p>
                            </div>
                        </div>
                    </v-card>
                </div>

                <v-card bordered noPadding class="span-9 flex fd-c ov-h">
                    <v-interactive-map :mapaPaises="mapaPaises" v-show="!mapaDadosLoading"/>
                    <v-interactive-map v-show="mapaDadosLoading"/>
                </v-card>
            </div>

            <div class="pt-6 ov-h mb-3" :class="{ 'placeholder-set': mercadoriasNCMLoading }">
                <p :class="{ 'placeholder-loading plw-10 plh-16': mercadoriasNCMLoading }">Top 10 NCMs do período</p>
                <p class="text-lg bold mb-2" :class="{ 'placeholder-loading plw-20 plh-40': mercadoriasNCMLoading }">Mercadorias por NCMs mais importadas</p>

                <div class="analytics-table-container mb-3 ov-a" v-show="!mercadoriasNCMLoading">
                    <table class="analytics-table">
                        <thead>
                            <tr>
                                <th
                                    v-for="column in columnsNcm"
                                    :key="`ncm-list-${column.label}`"
                                    :style="{ cursor: 'pointer' }"
                                    @click="sortByColumn(column.field)"
                                >
                                    <div
                                        class="flex ai-c"
                                        :class="{ 'jc-fe': column.align === 'right', 'jc-fs': column.align === 'left' }"
                                        :style="{ gap : '2px' }"
                                    >
                                        {{ column.label }}
                                        <small v-if="column.cifrao === true" class="text-sm">(R$)</small>
                                        <move-up-icon size="16" v-if="sortDirection === 1 && sortedColumn === column.field"/>
                                        <move-down-icon size="16" v-else-if="sortDirection === -1 && sortedColumn === column.field"/>
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(item, index) in mercadoriasNCM.slice(0, 10)" :key="`${item.id}-${index}`">
                                <td class="text-clip">{{ item.nome_adquirente }}</td>
                                <td class="text-right">{{ item.codigo_ncm | ncmFormat }}</td>
                                <td class="text-right">{{ item.vmld | moneyBRL }}</td>
                                <td class="text-right">{{ item.vmle | moneyBRL }}</td>
                                <td class="text-right">{{ item.frete | moneyBRL }}</td>
                                <td class="text-right">{{ item.ii | moneyBRL }}</td>
                                <td class="text-right">{{ item.ipi | moneyBRL }}</td>
                                <td class="text-right">{{ item.pis | moneyBRL }}</td>
                                <td class="text-right">{{ item.cofins | moneyBRL }}</td>
                                <td class="text-right">{{ item.antidumping | moneyBRL }}</td>
                                <td class="text-right">{{ item.siscomex | moneyBRL }}</td>
                            </tr>
                            <tr v-if="mercadoriasNCM.length == 0">
                                <td colspan="12" class="text-center">Nenhuma mercadoria encontrada</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div v-show="mercadoriasNCMLoading" :class="{ 'placeholder-set': mercadoriasNCMLoading }">
                    <div class="flex ai-c jc-c gap1" v-for="n in 10" :key="n">
                        <div :class="{ 'placeholder-loading plw-100 plh-30': mercadoriasNCMLoading }"></div>
                        <div :class="{ 'placeholder-loading plw-100 plh-30': mercadoriasNCMLoading }"></div>
                        <div :class="{ 'placeholder-loading plw-100 plh-30': mercadoriasNCMLoading }"></div>
                        <div :class="{ 'placeholder-loading plw-100 plh-30': mercadoriasNCMLoading }"></div>
                        <div :class="{ 'placeholder-loading plw-100 plh-30': mercadoriasNCMLoading }"></div>
                        <div :class="{ 'placeholder-loading plw-100 plh-30': mercadoriasNCMLoading }"></div>
                        <div :class="{ 'placeholder-loading plw-100 plh-30': mercadoriasNCMLoading }"></div>
                        <div :class="{ 'placeholder-loading plw-100 plh-30': mercadoriasNCMLoading }"></div>
                    </div>
                </div>

                <div class="flex ai-c fgap2 jc-c max-w-1000" v-show="!mercadoriasNCMLoading && mercadoriasNCM.length > 0">
                    <a href="#" @click.prevent="modalNcm = true" class="btn-analytics" style="flex: 0 1 50%">
                    Visualizar Todas as NCMs do Período
                    </a>
                </div>
            </div>

            <hr class="light m-0" />

            <div class="pt-3">
                <div class="columns gap2">
                    <div class="span-4" :class="{ 'placeholder-set': paisesOrigemDadosLoading }">
                        <p :class="{ 'placeholder-loading plw-30 plh-16': paisesOrigemDadosLoading }">Top 10 do Período</p>
                        <p class="text-lg bold mb-2" :class="{ 'placeholder-loading plw-100 plh-40': paisesOrigemDadosLoading }">Locais de Origens</p>
                        <v-card theme="light">
                            <ul>
                                <li v-for="(item, index) in paisesOrigemDados.slice(0, 10)" :key="`${item.id}-${index}`" class="flex fgap1 pv-1 ai-gc" v-show="!paisesOrigemDadosLoading">
                                    <span style="min-width: 20px"> {{ index + 1 }}º </span>

                                    <div class="flex flex1 fgap1">
                                        <img :src="`/flags/${countryCodesList[item.fabricante_codigo_pais]?.sigla}.svg`" style="width: 50px" :alt="countryCodesList[item.fabricante_codigo_pais]?.nome">
                                        <div>
                                            <p class="text-nm"> {{ item.fabricante_cidade }} </p>
                                            <p class="text-sm bold"> {{countryCodesList[item.fabricante_codigo_pais]?.nome}}</p>
                                        </div>
                                    </div>

                                    <p class="text-nm text-medium bold">{{ item.total | moneyBRL }} </p>
                                </li>
                                <li v-for="n in 10" :key="`n-${n}`" class="flex fgap1 pv-1 ai-gc" v-show="paisesOrigemDadosLoading">
                                    <div :class="{ 'placeholder-loading plw-100 plh-40': paisesOrigemDadosLoading }"></div>
                                </li>
                                <li v-if="paisesOrigemDados.length == 0" class="flex fgap1 pv-1 ai-gc">
                                    <span>Nenhum local de origem encontrado</span>
                                </li>
                            </ul>
                            <div class="mt-3 flex fd-c fgap2" v-show="!paisesOrigemDadosLoading && paisesOrigemDados.length > 0">
                                <a href="#" @click.prevent="modalPaisesOrigem = true" class="btn-analytics white">Visualizar Todos os Locais de Origens do Período</a>
                            </div>
                        </v-card>
                    </div>
                    <div class="span-4" :class="{ 'placeholder-set': exportadoresDadosLoading }">
                        <p :class="{ 'placeholder-loading plw-30 plh-16': exportadoresDadosLoading }">Top 10 do Período</p>
                        <p class="text-lg bold mb-2" :class="{ 'placeholder-loading plw-100 plh-40': exportadoresDadosLoading }">Exportadores</p>
                        <v-card bordered>
                            <ul>
                                <li v-for="(item, index) in exportadoresDados.slice(0, 10)" :key="`${item.id}-${index}`" class="flex fgap1 pv-1 ai-gc" v-show="!exportadoresDadosLoading">
                                    <span style="min-width: 20px"> {{ index + 1 }}º </span>

                                    <div class="flex flex1 fgap1">
                                        <img :src="`/flags/${countryCodesList[item.exportador_codigo_pais]?.sigla}.svg`" style="width: 50px" :alt="countryCodesList[item.exportador_codigo_pais]?.nome">
                                        <div>
                                        <p class="text-nm"> {{ item.exportador_nome }} </p>
                                        <p class="text-sm bold">{{countryCodesList[item.exportador_codigo_pais]?.nome}}</p>
                                        </div>
                                    </div>

                                    <p class="text-nm text-medium bold">{{ item.total | moneyBRL }} </p>
                                </li>
                                <li v-for="n in 10" :key="`n1-${n}`" class="flex fgap1 pv-1 ai-gc" v-show="exportadoresDadosLoading">
                                    <div :class="{ 'placeholder-loading plw-100 plh-40': exportadoresDadosLoading }"></div>
                                </li>
                                <li v-if="exportadoresDados.length == 0" class="flex fgap1 pv-1 ai-gc">
                                    <span>Nenhum exportador encontrado</span>
                                </li>
                            </ul>
                            <div class="mt-3 flex fd-c fgap2" v-show="!exportadoresDadosLoading && exportadoresDados.length > 0">
                                <a href="#" @click.prevent="modalExportadores = true" class="btn-analytics">Visualizar Todos os Exportadores do Período</a>
                            </div>
                        </v-card>
                    </div>
                    <div class="span-4" :class="{ 'placeholder-set': fabricantesDadosLoading }">
                        <p :class="{ 'placeholder-loading plw-30 plh-16': fabricantesDadosLoading }">Top 10 do Período</p>
                        <p class="text-lg bold mb-2" :class="{ 'placeholder-loading plw-100 plh-40': fabricantesDadosLoading }">Fabricantes</p>
                        <v-card theme="light">
                            <ul>
                                <li v-for="(item, index) in fabricantesDados.slice(0, 10)" :key="`${item.id}-${index}`" class="flex fgap1 pv-1 ai-gc" v-show="!fabricantesDadosLoading">
                                    <span style="min-width: 20px"> {{ index + 1 }}º </span>

                                    <div class="flex flex1 fgap1">
                                        <img :src="`/flags/${countryCodesList[item.fabricante_codigo_pais]?.sigla}.svg`" style="width: 50px" :alt="countryCodesList[item.fabricante_codigo_pais]?.nome">
                                        <div>
                                            <p class="text-nm"> {{ item.fabricante_nome }} </p>
                                            <p class="text-sm bold">{{countryCodesList[item.fabricante_codigo_pais]?.nome}}</p>
                                        </div>
                                    </div>

                                    <p class="text-nm text-medium bold">{{ item.total | moneyBRL }} </p>
                                </li>
                                <li v-for="n in 10" :key="`n2-${n}`" class="flex fgap1 pv-1 ai-gc" v-show="fabricantesDadosLoading">
                                    <div :class="{ 'placeholder-loading plw-100 plh-40': fabricantesDadosLoading }"></div>
                                </li>
                                <li v-if="fabricantesDados.length == 0" class="flex fgap1 pv-1 ai-gc">
                                    <span>Nenhum fabricante encontrado</span>
                                </li>
                            </ul>
                            <div class="mt-3 flex fd-c fgap2" v-show="!fabricantesDadosLoading && fabricantesDados.length > 0">
                                <a href="#" @click.prevent="modalFabricantes = true" class="btn-analytics white">Visualizar Todos os Fabricantes do Período</a>
                            </div>
                        </v-card>
                    </div>
                </div>
            </div>
        </v-card>
    </div>

    <v-drawer
      title="Filtrar Pesquisa"
      :handler="drawerOpen"
      @close="closeDrawer"
    >
        <template #content>
            <form @submit.prevent class="flex fd-c fgap2">
                <div v-if="modalList.length > 1" >
                    <Select title="MODAL" :options="modalList" v-model="filters.modal" @input="generateData()"/>
                </div>
                <div v-if="incotermsList.length > 1" >
                    <Select title="ICONTERMS" :options="incotermsList" v-model="filters.incoterm" @input="generateData()"/>
                </div>
                <div v-if="urfDespachoList.length > 1" >
                    <Select title="URF DE DESPACHO" :options="urfDespachoList" v-model="filters.urf_despacho" @input="generateData()"/>
                </div>
                <div v-if="urfEntradaList.length > 1" >
                    <Select title="URF DE ENTRADA" :options="urfEntradaList" v-model="filters.urf_entrada" @input="generateData()" />
                </div>
                <div v-if="recintoAlfandegadoList.length > 1" >
                    <Select title="RECINTO ALFANDEGADO" :options="recintoAlfandegadoList" v-model="filters.recinto" @input="generateData()" />
                </div>
                <div v-if="exportadoresList.length > 1" >
                    <Select title="EXPORTADOR" :options="exportadoresList" v-model="filters.exportador" @input="generateData()"/>
                </div>
                <div v-if="fabricantesList.length > 1" >
                    <Select title="FABRICANTE" :options="fabricantesList" v-model="filters.fabricante" @input="generateData()"/>
                </div>
                <div v-if="paisAquisicaoList.length > 1" >
                    <Select title="PAÍS DE AQUISIÇÃO" :options="paisAquisicaoList" v-model="filters.pais_aquisicao" @input="generateData()" />
                </div>
                <div v-if="paisOrigemList.length > 1" >
                    <Select title="PAÍS DE ORIGEM" :options="paisOrigemList" v-model="filters.pais_origem" @input="updatePaisesOrigem($event)" />
                </div>
                <div v-if="moedaList.length > 1" >
                    <Select title="MOEDA NEGOCIADA" :options="moedaList" v-model="filters.moeda_negociada" @input="generateData()" />
                </div>
            </form>
        </template>
        <!-- <template #footer="{ close }">
            <div class="flex ai-c jc-sb fw-w fgap2">
                <button class="btn danger solid small" @click.prevent="close"> Fechar </button>
                <button class="btn primary solid small" @click.prevent="generateData"> Filtrar </button>
            </div>
        </template> -->
    </v-drawer>

    <Modal :handler="modalNcm" @request-close="modalNcm = false" :width="100" :maxWidth="95">
      <template #head>
        <h4>Todas as NCMs do Período</h4>
      </template>

      <template #content>
        <div class="analytics-table-container mb-2">
          <table class="analytics-table">
            <thead>
                <tr>
                    <th
                        v-for="column in columnsNcm"
                        :key="`ncm-list-modal-${column.label}`"
                        :style="{ cursor: 'pointer' }"
                        @click="sortByColumn(column.field)"
                    >
                        <div
                            class="flex ai-c"
                            :class="{ 'jc-fe': column.align === 'right', 'jc-fs': column.align === 'left' }"
                            :style="{ gap : '2px' }"
                        >
                            {{ column.label }}
                            <small v-if="column.cifrao === true" class="text-sm">(R$)</small>
                            <move-up-icon size="16" v-if="sortDirection === 1 && sortedColumn === column.field"/>
                            <move-down-icon size="16" v-else-if="sortDirection === -1 && sortedColumn === column.field"/>
                        </div>
                    </th>
                </tr>
            </thead>

            <tbody>
              <tr
                v-for="(item, index) in mercadoriasNCM"
                :key="`${item.id}-${index}`"
              >
                <td class="text-clip">{{ item.nome_adquirente }}</td>
                <td class="text-right">{{ item.codigo_ncm | ncmFormat }}</td>
                <td class="text-right">{{ item.vmld | moneyBRL }}</td>
                <td class="text-right">{{ item.vmle | moneyBRL }}</td>
                <td class="text-right">{{ item.frete | moneyBRL }}</td>
                <td class="text-right">{{ item.ii | moneyBRL }}</td>
                <td class="text-right">{{ item.ipi | moneyBRL }}</td>
                <td class="text-right">{{ item.pis | moneyBRL }}</td>
                <td class="text-right">{{ item.cofins | moneyBRL }}</td>
                <td class="text-right">{{ item.antidumping | moneyBRL }}</td>
                <td class="text-right">{{ item.siscomex | moneyBRL }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </Modal>

    <Modal :handler="modalPaisesOrigem" @request-close="modalPaisesOrigem = false" :minWidth="35">
        <template #head>
            <h4>Todos os Locais de Origens no Período</h4>
        </template>

        <template #content>
            <div class="mb-2">
                <ul>
                    <li v-for="(item, index) in paisesOrigemDados" :key="`${item.id}-${index}`" class="flex fgap1 pv-1 ai-gc">
                        <span style="min-width: 20px"> {{ index + 1 }}º </span>

                        <div class="flex flex1 fgap1">
                            <img :src="`/flags/${countryCodesList[item.fabricante_codigo_pais]?.sigla}.svg`" style="width: 50px" :alt="countryCodesList[item.fabricante_codigo_pais]?.nome">
                            <div>
                                <p class="text-nm"> {{ item.fabricante_cidade }} </p>
                                <p class="text-sm bold"> {{countryCodesList[item.fabricante_codigo_pais]?.nome}}</p>
                            </div>
                        </div>

                        <p class="text-nm text-medium bold">{{ item.total | moneyBRL }} </p>
                    </li>
                </ul>
            </div>
        </template>
    </Modal>

    <Modal :handler="modalExportadores" @request-close="modalExportadores = false" :minWidth="35">
        <template #head>
            <h4>Todos os Exportadores no Período</h4>
        </template>

        <template #content>
            <div class="mb-2">
                <ul>
                    <li v-for="(item, index) in exportadoresDados" :key="`${item.id}-${index}`" class="flex fgap1 pv-1 ai-gc">
                        <span style="min-width: 20px"> {{ index + 1 }}º </span>

                        <div class="flex flex1 fgap1">
                            <img :src="`/flags/${countryCodesList[item.exportador_codigo_pais]?.sigla}.svg`" style="width: 50px" :alt="countryCodesList[item.exportador_codigo_pais]?.nome">
                            <div>
                            <p class="text-nm"> {{ item.exportador_nome }} </p>
                            <p class="text-sm bold">{{countryCodesList[item.exportador_codigo_pais]?.nome}}</p>
                            </div>
                        </div>

                        <p class="text-nm text-medium bold">{{ item.total | moneyBRL }} </p>
                    </li>
                </ul>
            </div>
        </template>
    </Modal>

    <Modal :handler="modalFabricantes" @request-close="modalFabricantes = false" :minWidth="35">
        <template #head>
            <h4>Todos os Fabricantes por Período</h4>
        </template>

        <template #content>
            <div class="mb-2">
                <ul>
                    <li v-for="(item, index) in fabricantesDados" :key="`${item.id}-${index}`" class="flex fgap1 pv-1 ai-gc">
                        <span style="min-width: 20px"> {{ index + 1 }}º </span>

                        <div class="flex flex1 fgap1">
                            <img :src="`/flags/${countryCodesList[item.fabricante_codigo_pais]?.sigla}.svg`" style="width: 50px" :alt="countryCodesList[item.fabricante_codigo_pais]?.nome">
                            <div>
                                <p class="text-nm"> {{ item.fabricante_nome }} </p>
                                <p class="text-sm bold">{{countryCodesList[item.fabricante_codigo_pais]?.nome}}</p>
                            </div>
                        </div>

                        <p class="text-nm text-medium bold">{{ item.total | moneyBRL }} </p>
                    </li>
                </ul>
            </div>
        </template>
    </Modal>

  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
// eslint-disable-next-line import/no-cycle
import apiBi from "@/services/apiBi";
import { OPERATIONAL_WAY_OF_TRANSPORTE } from "@/utils/consts";
import { MONEY_LABELS } from "@/utils/consts";
import { COUNTRY_CODES } from "@/utils/consts";
import { COLORS } from "@/utils/consts";
// eslint-disable-next-line import/no-extraneous-dependencies
import { endOfYear, format, startOfYear, subYears, startOfMonth, endOfMonth } from "date-fns";
import { modalSelectOptions } from "@/utils/BiUtils";
import VBadge from "@/components/Analytics/VBadge.vue";
import VBarChart from "@/components/Analytics/VBarChart.vue";
import VCard from "@/components/Analytics/VCard.vue";
import VDonutChart from "@/components/Analytics/VDonutChart.vue";
import VHorizontalTimeline from "@/components/Analytics/VHorizontalTimeline.vue";
import VLargeBarChar from "@/components/Analytics/VLargeBarChar.vue";
import DropDown from "@/components/DropDown.vue";
import MoveUpIcon from "@/components/Icons/Analytics/MoveUpIcon.vue";
import MoveDownIcon from "@/components/Icons/Analytics/MoveDownIcon.vue";
import PageTitle from "@/components/PageTitle.vue";
import VInputTaggable from "@/components/DataInput/InputTaggable.vue";
import { GoalIcon, AwardIcon, ExcelIcon } from "@/components/Icons";
import VGauge from "@/components/Analytics/VGauge.vue";
import VInteractiveMap from "@/components/Analytics/VInteractiveMap.vue";
import VDrawer from "@/components/VDrawer.vue";
import Select from "@/components/DataInput/Select.vue";
import Modal from "@/components/Modal.vue";
import { da } from "date-fns/locale";
import moment from "moment";
import api from '@/services/api';
import VTooltipVue from "../../components/Analytics/VTooltip.vue";

export default {
  name: "AnalyticsView",
  components: {
    PageTitle,
    VCard,
    DropDown,
    MoveUpIcon,
    VLargeBarChar,
    VBadge,
    VDonutChart,
    VBarChart,
    VHorizontalTimeline,
    VInputTaggable,
    GoalIcon,
    AwardIcon,
    ExcelIcon,
    VGauge,
    VInteractiveMap,
    VDrawer,
    Select,
    Modal,
    MoveDownIcon,
    VTooltipVue
  },
  props: {
    pageTitle: {
      type: String,
      default: "",
    },
    breadcrumbName: {
      type: String,
      default: "",
    },
  },
  filters: {
    ncmFormat(value) {
        try {
            if (value === 'N/I' || value === undefined || value === null) return 'N/I';
            return `${value.slice(0, -4)}.${value.slice(-4, -2)}.${value.slice(-2)}`;
        } catch (e) {
            return value;
        }
    },
    moneyBRL(value) {
      try {
        return new Intl.NumberFormat("pt-BR").format(parseFloat(value));
      } catch (e) {
        return new Intl.NumberFormat("pt-BR").format(0);
      }
    },
    formtatDateMonthYear(date) {
      const dataFormatada = date.replace(
        /(\d{2})-(\d{4})/,
        (match, mes, ano) => {
          const meses = [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
          ];
          return `${meses[mes - 1]}/${ano.slice(2)}`;
        }
      );
      return dataFormatada;
    },
    formtatDateDayMonth(date) {
        const [year, month, day] = date.split('-');
        const meses = [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
        ];

        const formattedDate = `${day}/${meses[parseInt(month) - 1]}`;

        return formattedDate;
    },
    labelFormat(label) {
        return label[0].toUpperCase() + label.slice(1).toLowerCase();
    },
    textPA(value) {
        if(value === 'UP') {
            return 'maior que o período anterior';
        } else {
             return 'menor que o período anterior';
        }
    }
  },
  data() {
    return {
        moneyLabelList: MONEY_LABELS,
        countryCodesList: COUNTRY_CODES,
        colorsList: COLORS,

        impostoPlaceholderLoading: true,
        processosPorModalLoading: true,
        valoresTotalVLMDeICMSLoading: true,
        valorFretesLoading: true,
        processosPorCanalLoading: true,
        mercadoriasNCMLoading: true,
        paisesOrigemDadosLoading: true,
        exportadoresDadosLoading: true,
        fabricantesDadosLoading: true,
        leadTimesDadosLoading: true,
        mapaNcmFiltroLoading: true,
        mapaDadosLoading: true,
        pesosImportadosLoading: true,
        mediasTaxaCambioLoading: true,
        processosPorPeriodoMesesLoading: true,
        processosPorPeriodoDiasLoading: true,
        processosPorPeriodoTipo: 'meses',
        columnsNcm: [
            {
                label: "Empresa",
                field: "nome_adquirente",
                align: "left",
                cifrao: false
            },
            {
                label: "NCM",
                field: "codigo_ncm",
                align: "right",
                cifrao: false
            },
            {
                label: `VMLD`,
                field: "vmld",
                align: "right",
                cifrao: true
            },
            {
                label: `VMLE`,
                field: "vmle",
                align: "right",
                cifrao: true
            },
            {
                label: `Frete`,
                field: "frete",
                align: "right",
                cifrao: true
            },
            {
                label: `I.I`,
                field: "ii",
                align: "right",
                cifrao: true
            },
            {
                label: `IPI`,
                field: "ipi",
                align: "right",
            },
            {
                label: `PIS/PASEP`,
                field: "pis",
                align: "right",
                cifrao: true
            },
            {
                label: `COFINS`,
                field: "cofins",
                align: "right",
                cifrao: true
            },
            {
                label: `Antidumping`,
                field: "antidumping",
                align: "right",
                cifrao: true
            },
            {
                label: `Siscomex`,
                field: "siscomex",
                align: "right",
                cifrao: true
            }
        ],

        incotermsList: [],
        modalList: [],
        modalListButton: [],
        modalButtonSelect: '',
        urfDespachoList: [],
        urfEntradaList: [],
        recintoAlfandegadoList: [],
        exportadoresList: [],
        fabricantesList: [],
        paisAquisicaoList: [],
        paisOrigemList: [],
        moedaList: [],
        pais: [""],
        ncm: [""],
        drawerOpen: false,
        impostos: {},
        opcoesModal: modalSelectOptions,
        processosPorModal: {},
        pesosImportados: {},
        processosPorCanal: {},
        valoresTotalVLMDeICMS: {},
        valoresTotalFretes: {
            labels: [],
            series: [],
        },
        mediasTaxaCambioBase: {},
        mediasTaxaCambio: {},
        mediasTaxaCambioPrincial: {},
        mediasTaxaCambioPeriodos: [],
        processosPorPeriodoMeses: [],
        processosPorPeriodoDias: [],
        mapaNcmFiltro : [],
        mapaPaisesOrigemFiltro: [],
        mapaPaises : {},
        mapaDados: {
            topNcm: 'N/I',
            topPais: 'N/I',
            totalImpostos: '0,00',
            totalProcessos: 0
        },
        paisesOrigemDados: [],
        exportadoresDados: [],
        fabricantesDados: [],
        userCompanies: [],
        leadTimesDados: [],
        valorFretes: {},
        mercadoriasNCM: [],
        modalNcm: false,
        modalFabricantes: false,
        modalExportadores: false,
        modalPaisesOrigem: false,
        dataInicioApiFiltro: '', // Data que foi enviada para a API
        dataFimApiFiltro: '', // Data que foi enviada para a API
        filters: {
            dataInicio: format(startOfMonth(new Date()), "dd/MM/yyyy"),
            dataFim: format(new Date(), "dd/MM/yyyy"),
            // dataInicio: '01/02/2024',
            // dataFim: '28/02/2024',
            companies_ids: '',
            modal: '',
            urf_despacho: '',
            urf_entrada: '',
            recinto: '',
            pais_aquisicao: '',
            pais_origem: '',
            incoterm: '',
            exportador: '',
            fabricante: '',
            moeda_negociada: '',
        },
        sortDirection: 1,
        sortedColumn: null,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    periodRange() {
      const dataInicio = this.filters.dataInicio.split("/");
      const dataFim = this.filters.dataFim.split("/");

      if (dataInicio[1] === dataFim[1] && dataInicio[2] === dataFim[2]) {
        return this.$options.filters.formtatDateMonthYear(`${dataInicio[1]}-${dataInicio[2]}`);
      } else {
        return `${this.$options.filters.formtatDateMonthYear( `${dataInicio[1]}-${dataInicio[2]}`)} - ${this.$options.filters.formtatDateMonthYear(`${dataFim[1]}-${dataFim[2]}`)}`;
      }
    },
    periodRangeDays() {

        if (this.mediasTaxaCambioPeriodos.length === 0) {
            return '';
        }

        if (this.mediasTaxaCambioPeriodos[0].label === this.mediasTaxaCambioPeriodos[this.mediasTaxaCambioPeriodos.length - 1].label) {
            return this.mediasTaxaCambioPeriodos[0].label;
        } else {
            return `${this.mediasTaxaCambioPeriodos[0].label} - ${this.mediasTaxaCambioPeriodos[this.mediasTaxaCambioPeriodos.length - 1].label}`;
        }
    },
    showFilters() {
        const arrays = [
            this.modalList,
            this.incotermsList,
            this.urfDespachoList,
            this.urfEntradaList,
            this.recintoAlfandegadoList,
            this.exportadoresList,
            this.fabricantesList,
            this.paisAquisicaoList,
            this.paisOrigemList,
            this.moedaList
        ];

        return arrays.some(array => array.length > 1);
    }
  },
  methods: {
    ...mapActions(["toggleLoading"]),
    ...mapActions('auth', ['updateUser']),
    vDonutChartFrete(val, opts) {
      return [
        `${this.valoresTotalFretes.labels[opts.seriesIndex]}`,
        this.$options.filters.moneyBRL(opts.w.config.series[opts.seriesIndex]),
      ];
    },
    sortByColumn(field) {
        if (this.sortedColumn === field) {
            this.sortDirection = -this.sortDirection;
        } else {
            this.sortedColumn = field;
            this.sortDirection = 1;
        }

        this.mercadoriasNCM = this.mercadoriasNCM.sort((a, b) => {
            const valorA = a[field];
            const valorB = b[field];
            if ( field !== 'nome_adquirente' ) {
                const valorANumber = parseFloat(valorA);
                const valorBNumber  = parseFloat(valorB);
                if (valorANumber < valorBNumber) {
                    return this.sortDirection;
                }
                if (valorANumber > valorBNumber) {
                    return -this.sortDirection;
                }
            } else {
                const valorAString = valorA.toString().toLowerCase();
                const valorBString = valorB.toString().toLowerCase();
                if (valorAString < valorBString) {
                    return this.sortDirection;
                }
                if (valorAString > valorBString) {
                    return -this.sortDirection;
                }
            }
            return 0;
        });
    },
    async buscarUserCompanies() {
        try {
            await this.toggleLoading(true);

            let listCompanies = [];
            if (this.user.companies !== undefined) {
                listCompanies = this.user.companies;
            } else {
                const userResponse = await api.get('/credential');
                this.updateUser(userResponse.data.data);
                listCompanies = userResponse.data.data.person.companies;
            }

            let listCompaniesIds = [7, 8, 3, 82, 2082, 166, 2378, 102, 2397, 2517, 119, 100, 24, 154];

            if (!listCompanies.some(company => listCompaniesIds.includes(company.id))) {
                await this.$router.push('/');
            }

            const createCompanyObject = (company) => {
                if (company.customer === true && company.customBroker === false) {
                    return { label: company.name.toUpperCase(), value: company.id };
                }
                return null;
            };

            this.userCompanies = [
                {
                    label: "TODAS",
                    value: "",
                },
                ...listCompanies.map(createCompanyObject).filter(Boolean),
            ];
        } catch (error) {
            console.error(error);
        } finally {
            await this.toggleLoading(false);
        }
    },
    async buscarImpostos() {
        this.impostoPlaceholderLoading = true;
        try {
            const response = await apiBi.get("/bi/valores-impostos", {
                params: {
                    ...this.organizaFiltros(),
                    data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
                    data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
                },
            });
            this.impostos = response.data.dados;
        } catch (e) {
            console.log(e);
            if (e.response) {
                await this.$toast.error(e.response.data.message);
            }
        } finally {

            this.impostoPlaceholderLoading = false;
        }
    },
    async buscarProcessosPorModal() {
      this.processosPorModalLoading = true;
      try {
        const response = await apiBi.get("/bi/processos-por-modal", {
          params: {
            ...this.organizaFiltros(),
            data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
            data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
          },
        });
        this.processosPorModal = response.data.dados;
      } catch (e) {
        console.log(e);
        if (e.response) {
          await this.$toast.error(e.response.data.message);
        }
      } finally {

        this.processosPorModalLoading = false;
      }
    },
    async buscarPesosImportados() {
        this.pesosImportadosLoading = true;
        try {
            const response = await apiBi.get("/bi/pesos-importados", {
                params: {
                ...this.organizaFiltros(),
                data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
                data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
                },
            });
            this.pesosImportados = response.data.dados;
        } catch (e) {
            console.log(e);
            if (e.response) {
                await this.$toast.error(e.response.data.message);
            }
        } finally {

            this.pesosImportadosLoading = false;
        }
    },
    async buscarMercadoriasNCM() {
      this.mercadoriasNCMLoading = true;
      try {
        const response = await apiBi.get("/bi/mercadorias-ncm", {
          params: {
            ...this.organizaFiltros(),
            data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
            data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
          },
        });
        this.mercadoriasNCM = response.data.dados;
      } catch (e) {
        console.log(e);
        if (e.response) {
          await this.$toast.error(e.response.data.message);
        }
      } finally {

        this.mercadoriasNCMLoading = false;
      }
    },
    async buscarProcessosPorCanal() {
      this.processosPorCanalLoading = true;
      try {
        const response = await apiBi.get("bi/processos-por-canal", {
          params: {
            ...this.organizaFiltros(),
            data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
            data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
          },
        });
        this.processosPorCanal = response.data.dados;
      } catch (e) {
        console.log(e);
        if (e.response) {
          await this.$toast.error(e.response.data.message);
        }
      } finally {

        this.processosPorCanalLoading = false;
      }
    },
    async buscarValorVLMDeICMS() {
      this.valoresTotalVLMDeICMSLoading = true;
      try {
        const response = await apiBi.get("/bi/valores-vmld-icms", {
          params: {
            ...this.organizaFiltros(),
            data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
            data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
          },
        });

        this.valoresTotalVLMDeICMS = response.data.dados;

      } catch (e) {
        console.log(e);
        if (e.response) {
          await this.$toast.error(e.response.data.message);
        }
      } finally {

        this.valoresTotalVLMDeICMSLoading = false;
      }
    },
    async buscarValoresTotalFrete() {
      this.valorFretesLoading = true;
      try {
        const response = await apiBi.get("/bi/valores-frete", {
          params: {
            ...this.organizaFiltros(),
            data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
            data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
          },
        });

        this.valorFretes = 0;
        this.valoresTotalFretes.labels = [];
        this.valoresTotalFretes.series = [];

        if (response.data.dados) {
            let dados = response.data.dados;
            this.valorFretes = dados;
            for (const chave in dados) {
                if (!isNaN(chave)) {
                    let item = dados[chave];
                    this.valoresTotalFretes.labels.push(item.modal.charAt(0).toUpperCase() + item.modal.slice(1).toLowerCase());
                    this.valoresTotalFretes.series.push(item.total);
                }
            }
        }
      } catch (e) {
        console.log(e);
        if (e.response) {
          await this.$toast.error(e.response.data.message);
        }
      } finally {

        this.valorFretesLoading = false;
      }
    },
    async buscarMediasTaxaCambio() {
      this.mediasTaxaCambioLoading = true;
      try {
        const response = await apiBi.get("/bi/medias-taxa-cambio", {
          params: {
            ...this.organizaFiltros(),
            data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
            data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
          },
        });

        this.mediasTaxaCambioBase = [];

        if (response.data.dados) {
            this.mediasTaxaCambioBase = response.data.dados;
            this.organizaDadosDaMediasTaxaCambio();
        }

        this.mediasTaxaCambioLoading = false;

      } catch (e) {
        console.log(e);
        if (e.response) {
          await this.$toast.error(e.response.data.message);
        }
      }
    },
    async buscarProcessoPorPeriodo() {
      try {
        const dataInicial = moment(this.sanitizeDate(this.filters.dataInicio));
        const dataFinal = moment(this.sanitizeDate(this.filters.dataFim));

        if (dataFinal.diff(dataInicial, 'days') <= 30) {
            this.processosPorPeriodoTipo = 'dias';
            this.processosPorPeriodoDiasLoading = true;
        } else {
            this.processosPorPeriodoTipo = 'meses';
            this.processosPorPeriodoMesesLoading = true;
        }

        const response = await apiBi.get("/bi/processos-por-periodo", {
          params: {
            ...this.organizaFiltros(),
            data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
            data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
          },
        });

        this.processosPorPeriodoMeses = [];
        this.processosPorPeriodoDias = [];

        if (dataFinal.diff(dataInicial, 'days') <= 30) {
            this.processosPorPeriodoDias =this.organizaDadosProcessoPorPeriodoDias(response.data.dados);
        } else {
            this.processosPorPeriodoMeses = this.organizaDadosProcessoPorPeriodoMeses(response.data.dados);
        }
      } catch (e) {
        console.log(e);
        if (e.response) {
          await this.$toast.error(e.response.data.message);
        }
      } finally {
        this.processosPorPeriodoDiasLoading = false;
        this.processosPorPeriodoMesesLoading = false;
      }
    },
    async buscarPaisesOrigemDados() {
        this.paisesOrigemDadosLoading = true;
        try {
            const response = await apiBi.get("/bi/paises-origem", {
                params: {
                    ...this.organizaFiltros(),
                    data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
                    data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
                },
            });

            this.paisesOrigemDados = response.data.dados;

        } catch (e) {
            console.log(e);
            if (e.response) {
                await this.$toast.error(e.response.data.message);
            }
        } finally {

            this.paisesOrigemDadosLoading = false;
        }
    },
    async buscarExportadoresDados() {
        this.exportadoresDadosLoading = true;
        try {
            const response = await apiBi.get("/bi/exportadores", {
                params: {
                    ...this.organizaFiltros(),
                    data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
                    data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
                },
            });

            this.exportadoresDados = response.data.dados;

        } catch (e) {
            console.log(e);
            if (e.response) {
                await this.$toast.error(e.response.data.message);
            }
        } finally {

            this.exportadoresDadosLoading = false;
        }
    },
    async buscarFabricantesDados() {
        this.fabricantesDadosLoading = true;
        try {
            const response = await apiBi.get("/bi/fabricantes", {
                params: {
                    ...this.organizaFiltros(),
                    data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
                    data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
                },
            });

            this.fabricantesDados = response.data.dados;

        } catch (e) {
            console.log(e);
            if (e.response) {
                await this.$toast.error(e.response.data.message);
            }
        } finally {

            this.fabricantesDadosLoading = false;
        }
    },
    async buscarProcessoPeriodoGescomex() {
        try {
            this.impostoPlaceholderLoading = true;
            this.processosPorModalLoading = true;
            this.valoresTotalVLMDeICMSLoading = true;
            this.valorFretesLoading = true;
            this.processosPorCanalLoading = true;
            this.mercadoriasNCMLoading = true;
            this.paisesOrigemDadosLoading = true;
            this.exportadoresDadosLoading = true;
            this.fabricantesDadosLoading = true;
            this.leadTimesDadosLoading = true;
            this.mapaNcmFiltroLoading = true;
            this.mapaDadosLoading = true;
            this.pesosImportadosLoading = true;
            this.mediasTaxaCambioLoading = true;
            this.processosPorPeriodoMesesLoading = true;
            this.processosPorPeriodoDiasLoading = true;

            let filters = { ...this.filters };
            let companies_ids = [];
            if (filters.companies_ids.length > 0) {
                companies_ids = filters.companies_ids.filter(option => option.value).map(option => option.value);
            } else {
                companies_ids = this.userCompanies.filter(option => option.value).map(option => option.value);
            }

            await this.toggleLoading({
				show: true,
				label: "Importando Dados",
				description: "Carregando dados, isso pode demorar alguns minutos...",
			});

            const response = await apiBi.get("/processos-importacao/importar-por-periodo-do-gescomex", {
                params: {
                    buscar_cotacoes_moedas: true,
                    data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
                    data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
                    companies_ids: companies_ids,
                },
            });

        } catch (e) {
            console.log(e);
            if (e.response) {
                await this.$toast.error(e.response.data.message);
            }
        } finally {
            await this.toggleLoading(false);
        }
    },
    async buscarFiltros() {
        try {
            const response = await apiBi.get("/bi/valores-filtros", {
                params: {
                    data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
                    data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
                },
            });

            if(response.data?.valores){

                const dados = response.data.valores;
                const criarLista = (lista) => [
                    { label: "TODOS", value: "" },
                    ...lista.map((dado) => ({ label: dado.toUpperCase(), value: dado })),
                ];

                const criarListaMoeda = (lista) => [
                    { label: "TODOS", value: "" },
                    ...Object.keys(lista).map((dado) => ({
                        label: lista[dado].moeda.toUpperCase(),
                        value: lista[dado].codigo,
                    })),
                ];

                this.incotermsList = dados?.incoterms ? criarLista(dados?.incoterms) : [];
                this.modalList = dados?.modais ? criarLista(dados?.modais) : [];
                this.modalListButton = [...this.modalList].sort((a, b) => {
                    if (a.label === "TODOS") {
                        return 1;
                    } else if (b.label === "TODOS") {
                        return -1;
                    } else {
                        return a.label.localeCompare(b.label);
                    }
                });
                this.urfDespachoList = dados?.urfs_despacho ? criarLista(dados?.urfs_despacho) : [];
                this.urfEntradaList = dados?.urfs_entrada ? criarLista(dados?.urfs_entrada) : [];
                this.recintoAlfandegadoList = dados?.recintos ? criarLista(dados?.recintos) : [];
                this.exportadoresList = dados?.exportadores ? criarLista(dados?.exportadores) : [];
                this.fabricantesList = dados?.fabricantes ? criarLista(dados?.fabricantes) : [];
                this.paisAquisicaoList = dados?.paises_aquisicao ? criarLista(dados?.paises_aquisicao) : [];
                this.paisOrigemList = dados?.paises_origem ? criarLista(dados?.paises_origem) : [];
                this.moedaList = dados?.moedas_negociada ? criarListaMoeda(dados?.moedas_negociada) : [];
            }

        } catch (e) {
            console.log(e);
            if (e.response) {
                await this.$toast.error(e.response.data.message);
            }
        } finally {

        }
    },
    async buscarLeadTimes(modal = '') {
        this.leadTimesDadosLoading = true;
        try {

            this.modalButtonSelect = modal;

            const response = await apiBi.get("/bi/lead-times", {
                params: {
                    ...this.organizaFiltros(),
                    data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
                    data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
                    ...(modal !== '' && { modal }),
                },
            });

            this.leadTimesDados = [];

            if(response.data?.dados){
                let dados = response.data.dados;
                let data = [
                    {
                        title: 'Registro de DI',
                        description:
                        '<p>Dias de diferença entre</p> <p><b>Presença de Carga X Registro</b></p>',
                        value: dados.hasOwnProperty("presenca-x-registro") ? dados["presenca-x-registro"] : 0,
                    },
                    {
                        title: 'Carregamento',
                        description:
                        '<p>Dias de diferença entre</p> <p><b>Desembaraço X Carregamento</b></p>',
                        value: dados.hasOwnProperty("desembaraco-x-carregamento") ? dados["desembaraco-x-carregamento"] : 0,
                    },
                    {
                        title: 'Entrega',
                        description:
                        '<p>Dias de diferença entre</p> <p><b>Presença de Carga X Entrega</b></p>',
                        value: dados.hasOwnProperty("preseca-x-entrega") ? dados["preseca-x-entrega"] : 0,
                    },
                    // {
                    //     title: 'Encerramento',
                    //     description:
                    //     '<p>Dias de diferença entre</p> <p><b>Entrega X Envio do Faturamento</b></p>',
                    //     value: 0,
                    // },
                ];

                this.leadTimesDados = data;
            }

        } catch (e) {
            console.log(e);
            if (e.response) {
                await this.$toast.error(e.response.data.message);
            }
        } finally {

            this.leadTimesDadosLoading = false;
        }
    },
    async buscarDadosMapaNcm(ncm = [], pais_origem = []) {
        this.mapaDadosLoading = true;
        try {
            this.mapaPaises = {};

            const response = await apiBi.get("/bi/valores-mapa-ncm", {
                params: {
                    ...this.organizaFiltros(),
                    data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
                    data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
                    ...(ncm.length > 0 && { ncm }),
                    ...(pais_origem.length > 0 && { pais_origem })
                },
            });

            this.mapaNcmFiltro = [];
            this.mapaPaisesOrigemFiltro = [];
            this.mapaPaises = {};
            this.mapaDados = {
                topNcm: 'N/I',
                topPais: 'N/I',
                totalImpostos: 'R$ 0,00',
                totalProcessos: 0
            };

            if (response.data) {
                const dados = response.data.data;

                let counter = 0;
                Object.keys(dados.paises).forEach(chave => {
                    if (counter > 25) {
                        counter = 0;
                    }
                    counter++;
                    let pais = dados.paises[chave];
                    this.mapaPaises[this.countryCodesList[pais.codigo].sigla.toUpperCase()] = this.colorsList[counter];
                });

                const criarLista = (lista) => [
                    { label: "TODOS", value: "" },
                    ...Object.keys(lista).map((dado) => ({
                        label: `${dado.slice(0, -4)}.${dado.slice(-4, -2)}.${dado.slice(-2)}`,
                        value: dado,
                    })),
                ];

                const criarListaPaises = (lista) => [
                    { label: "TODOS", value: "" },
                    ...Object.keys(lista).map((dado) => ({
                        label: lista[dado].pais.toUpperCase(),
                        value: lista[dado].pais,
                    })),
                ];

                this.mapaNcmFiltro =  dados?.ncms ? criarLista(dados?.ncms) : [];
                this.mapaPaisesOrigemFiltro =  dados?.paises ? criarListaPaises(dados?.paises) : [];
                const paisComMaiorValor = Object.values(dados.paises).reduce((max, pais) => {
                    if (pais.valor > max.valor) { return pais; }
                    return max;
                }, { valor: -Infinity });

                let listNcm = dados.ncms;

                if ( ncm.length > 0){
                    listNcm = Object.keys(listNcm).reduce((acc, key) => {
                        if (ncm.includes(key)) {
                            acc[key] = listNcm[key];
                        }
                        return acc;
                    }, {});
                }

                const ncmComMaisProcessos = Object.keys(listNcm).reduce((max, atual) => {
                    return listNcm[atual] > listNcm[max] ? atual : max;
                }, Object.keys(listNcm)[0]);

                this.mapaDados.topNcm = ncmComMaisProcessos;
                this.mapaDados.topPais = paisComMaiorValor.pais ? paisComMaiorValor.pais.toUpperCase() : 'N/I';
                this.mapaDados.totalImpostos = dados?.totais_primeiro_pais?.total_com_impostos && dados?.totais_primeiro_pais?.total_com_impostos != 'R$ 0' ? dados?.totais_primeiro_pais?.total_com_impostos : 'R$ 0,00';
                this.mapaDados.totalProcessos = paisComMaiorValor?.valor && paisComMaiorValor?.valor != -Infinity ? paisComMaiorValor?.valor : 0;
            }
        } catch (e) {
            console.log(e);
            if (e.response) {
                await this.$toast.error(e.response.data.message);
            }

            this.mapaNcmFiltro = [];
            this.mapaPaisesOrigemFiltro = [];
            this.mapaPaises = {};
            this.mapaDados = {
                topNcm: 'N/I',
                topPais: 'N/I',
                totalImpostos: 'R$ 0,00',
                totalProcessos: 0
            };
        } finally {

            this.mapaDadosLoading = false;
        }
    },
    async filtarMapaPaisesOrigem(events) {
        let ncm = this.ncm.filter(event => event !== "");
        let pais = events.filter(event => event !== "");
        if (events?.[events.length - 1] === ""){
            this.pais = [""];
            pais = '';
        } else {
            this.pais = events.length == 0 ? [""] : events.length == 1 ? events : events.filter(event => event !== "");
        }
        await this.buscarDadosMapaNcm(ncm, pais);
    },
    async filtarMapaNcm(events) {
        let ncm = events.filter(event => event !== "");
        let pais = this.pais.filter(event => event !== "");
        if (events?.[events.length - 1] === ""){
            this.ncm = [""];
            ncm = '';
        } else {
            this.ncm = events.length == 0 ? [""] : events.length > 1 ? events.filter(event => event !== "") : events;
        }
        await this.buscarDadosMapaNcm(ncm, pais);
    },
    async updatePaisesOrigem(event) {
        this.pais = [event];
        await this.generateData();
    },
    async downloadExcel() {
        try {
            await this.toggleLoading(true);
            const response = await apiBi.get('/bi/exportar/exportar-itens', {
                params: {
                    ...this.organizaFiltros(),
                    data_registro_di_inicial: this.sanitizeDate(this.filters.dataInicio),
                    data_registro_di_final: this.sanitizeDate(this.filters.dataFim),
                },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `analise-operacional.xlsx`);
            document.body.appendChild(link);
            link.click();
        } catch (e) {
            console.log(e);
            if (e.response) {
                await this.$toast.error(e.response.data.message);
            }
        } finally {
            await this.toggleLoading(false);
        }
    },
    organizaFiltros() {
        let filters = { ...this.filters };
        if (filters.companies_ids.length > 0) {
            filters.companies_ids = filters.companies_ids.filter(option => option.value).map(option => option.value);
        }

        delete filters.dataInicio;
        delete filters.dataFim;
        return filters;
    },
    organizaDadosProcessoPorPeriodoMeses(dadosMeses) {
        const [diaInicial, mesInicial, anoInicial] = this.filters.dataInicio.split("/");
        const [diaFinal, mesFinal, anoFinal] = this.filters.dataFim.split("/");
        const meses = [];

        for (let ano = anoInicial; ano <= anoFinal; ano++) {
            for ( let mes = ano === anoInicial ? mesInicial : 1; mes <= (ano === anoFinal ? mesFinal : 12); mes++ ) {
                let mesConsulta = mes - 1;
                const label = `${this.getMesNome(mesConsulta)} ${ano.toString().slice(-2)}`;
                const value = dadosMeses.find((dado) => dado.ano == ano && dado.mes == mes)?.total || 0;
                meses.push({ label, value });
            }
        }

        if (meses.length < 12) {
            const proximoMes = meses[meses.length - 1];
            let [month, year] = proximoMes.label.split(' ');
            let monthIndex = this.getMesNumero(month);
            let nextYear = parseInt(year);

            for (let i = meses.length; i < 12; i++) {
                monthIndex = (monthIndex + 1) % 12;
                if (monthIndex === 0) {
                    nextYear++;
                }
                let nextMonthLabel = `${this.getMesNome(monthIndex)} ${nextYear}`;
                meses.push({
                    label: nextMonthLabel,
                    value: 0,
                    opacity: true
                });
            }
        }


      return meses;
    },
    organizaDadosProcessoPorPeriodoDias(dadosDias) {
        const dataInicial = this.sanitizeDate(this.filters.dataInicio).split('-').map(Number);;
        const dataFinal = this.sanitizeDate(this.filters.dataFim).split('-').map(Number);;

        const dias = [];

        const diaInicial = dataInicial[2];
        const diaFinal = dataFinal[2];
        const mesInicial = dataInicial[1];
        const mesFinal = dataFinal[1];
        const anoInicial = dataInicial[0];
        const anoFinal = dataFinal[0];

        for (let ano = anoInicial; ano <= anoFinal; ano++) {
            for ( let mes = ano === anoInicial ? mesInicial : 1; mes <= (ano === anoFinal ? mesFinal : 12); mes++ ) {
                const diasNoMes = new Date(ano, mes, 0).getDate();
                for ( let dia = ano === anoInicial && mes === mesInicial ? diaInicial : 1; dia <= (ano === anoFinal && mes === mesFinal ? diaFinal : diasNoMes); dia++ ) {
                    const label = `${dia} ${this.getMesNome(mes - 1)}`;
                    const value = dadosDias.find((dado) => dado.mes === mes && dado.dia === dia) ?.total || 0;
                    dias.push({ label, value });
                }
            }
        }
        // const dataInicial = this.sanitizeDate(this.filters.dataInicio);
        // const dataFinal = this.sanitizeDate(this.filters.dataFim);

        // const dias = [];
        // const anoInicial = new Date(dataInicial).getFullYear();
        // const anoFinal = new Date(dataFinal).getFullYear();

        // const mesInicial = new Date(dataInicial).getMonth() + 1;
        // const mesFinal = new Date(dataFinal).getMonth();

        // const diaInicial = new Date(dataInicial).getDate() + 1;
        // const diaFinal = new Date(dataFinal).getDate() + 1;

        // for (let ano = anoInicial; ano <= anoFinal; ano++) {
        //     for ( let mes = ano === anoInicial ? mesInicial : 1; mes <= (ano === anoFinal ? mesFinal + 1 : 12); mes++ ) {
        //         const diasNoMes = new Date(ano, mes, 0).getDate();
        //         for ( let dia = ano === anoInicial && mes === mesInicial ? diaInicial : 1; dia <= (ano === anoFinal && mes === mesFinal + 1 ? diaFinal : diasNoMes); dia++ ) {
        //             const label = `${dia} ${this.getMesNome(mes - 1)}`;
        //             const value = dadosDias.find((dado) => dado.mes === mes && dado.dia === dia) ?.total || 0;
        //             dias.push({ label, value });
        //         }
        //     }
        // }

        if (dias.length < 12) {

            const dataIncialRestante = new Date(dataFinal);
            dataIncialRestante.setDate(dataIncialRestante.getDate() + 2);
            const dataFinalRestante = new Date(dataIncialRestante);
            dataFinalRestante.setDate(dataFinalRestante.getDate() + (11 - dias.length));

            const anoInicialRestante = new Date(dataIncialRestante).getFullYear();
            const anoFinalRestante = new Date(dataFinalRestante).getFullYear();
            const mesInicialRestante = new Date(dataIncialRestante).getMonth() + 1;
            const mesFinalRestante = new Date(dataFinalRestante).getMonth();
            const diaInicialRestante = new Date(dataIncialRestante).getDate();
            const diaFinalRestante = new Date(dataFinalRestante).getDate();

            for (let ano = anoInicialRestante; ano <= anoFinalRestante; ano++) {
                for ( let mes = ano === anoInicialRestante ? mesInicialRestante : 1; mes <= (ano === anoFinalRestante ? mesFinalRestante + 1 : 12); mes++ ) {
                    const diasNoMes = new Date(ano, mes, 0).getDate();
                    for ( let dia = ano === anoInicialRestante && mes === mesInicialRestante ? diaInicialRestante : 1; dia <= (ano === anoFinalRestante && mes === mesFinalRestante + 1 ? diaFinalRestante : diasNoMes); dia++ ) {
                        const label = `${dia} ${this.getMesNome(mes - 1)}`;
                        const value = 0;
                        const opacity = true;
                        dias.push({ label, value, opacity })
                    }
                }
            }
        }
      return dias;
    },
    getMesNome(mes) {
      const mesesDoAno = [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ];
      return mesesDoAno[mes];
    },
    getMesNumero(nomeMes) {
        const mesesDoAno = [
            "Jan",
            "Fev",
            "Mar",
            "Abr",
            "Mai",
            "Jun",
            "Jul",
            "Ago",
            "Set",
            "Out",
            "Nov",
            "Dez",
        ];
        return mesesDoAno.indexOf(nomeMes);
    },
    organizaDadosDaMediasTaxaCambio(currency = null) {
        this.mediasTaxaCambioPrincial = {};
        this.mediasTaxaCambio = {};
        this.mediasTaxaCambioPeriodos = [];

        // Cotação principall
        let dados = this.mediasTaxaCambioBase;
        let { index, data } = this.getCurrencyData(dados, currency);
        this.mediasTaxaCambioPrincial = { [index]: data };

        let listDado = { ...dados };

        // Quando for meses
        if (data?.periodos) {
            let objeto = data.periodos;
            this.mediasTaxaCambioPeriodos = Object.keys(objeto).map((label) => ({
                label: this.$options.filters.formtatDateMonthYear(label),
                value: objeto[label],
            }));

            if (this.mediasTaxaCambioPeriodos.length < 12) {
                let proximoMes = this.mediasTaxaCambioPeriodos[this.mediasTaxaCambioPeriodos.length - 1];

                let [month, year] = proximoMes.label.split('/');
                let monthIndex = this.getMesNumero(month);
                let nextYear = parseInt(year);

                for (let i = this.mediasTaxaCambioPeriodos.length; i < 12; i++) {
                    monthIndex = (monthIndex + 1) % 12;
                    if (monthIndex === 0) {
                        nextYear++;
                    }
                    let nextMonthLabel = `${this.getMesNome(monthIndex)}/${nextYear}`;
                    this.mediasTaxaCambioPeriodos.push({
                        label: nextMonthLabel,
                        value: proximoMes.value,
                        opacity: true
                    });
                }
            }
        }

        // Quando for dias
        if (data?.cotacoes) {
            let objeto = data.cotacoes;
            this.mediasTaxaCambioPeriodos = objeto.map((cotacao) => ({
                label: this.$options.filters.formtatDateDayMonth(cotacao.data_referencia),
                value: parseFloat(cotacao.valor_compra).toFixed(2),
            }));
        }

        delete listDado[index];
        this.mediasTaxaCambio = listDado;
    },
    getCurrencyData(data, currency = null) {
        const keys = Object.keys(data);
        const selectedCurrency = currency && keys.includes(currency) ? currency : keys[0];
        return { index: selectedCurrency, data: data[selectedCurrency] };
    },
    closeDrawer() {
      this.drawerOpen = false;
    },
    sanitizeDate(date) {
      const [d, m, y] = date.split("/");
      const iDate = new Date(y, parseInt(m) - 1, d);
      return format(iDate, "yyyy-MM-dd");
    },
    sanitizeDateTooltip(date) {
      const [d, m, y] = date.split("/");
      const iDate = new Date(parseInt(y) - 1, parseInt(m) - 1, d);
      return format(iDate, "dd/MM/yy");
    },
    async generateData() {

        if (!this.filters.dataInicio || !this.filters.dataFim) {
            return;
        }

        this.modalButtonSelect = '';

        const dataInicial = this.sanitizeDate(this.filters.dataInicio);
        const dataFinal = this.sanitizeDate(this.filters.dataFim);

        this.sortDirection = 1;
        this.sortedColumn = null;

        if (dataFinal < dataInicial) {
            await this.$toast.error("Data final não pode ser menor que data inicial");
            return;
        }

        // Data para o Tooltip
        this.dataInicioApiFiltro = this.sanitizeDateTooltip(this.filters.dataInicio);
        this.dataFimApiFiltro = this.sanitizeDateTooltip(this.filters.dataFim);

        // await this.buscarProcessoPeriodoGescomex();

        await Promise.all([
            this.buscarFiltros(),
            this.buscarImpostos(),
            this.buscarProcessosPorModal(),
            this.buscarPesosImportados(),
            this.buscarMercadoriasNCM(),
            this.buscarProcessosPorCanal(),
            this.buscarValorVLMDeICMS(),
            this.buscarValoresTotalFrete(),
            this.buscarMediasTaxaCambio(),
            this.buscarProcessoPorPeriodo(),
            this.buscarPaisesOrigemDados(),
            this.buscarExportadoresDados(),
            this.buscarFabricantesDados(),
            this.buscarLeadTimes(),
            this.buscarDadosMapaNcm(),
        ]);

        this.drawerOpen = false;
    },
  },
  async mounted() {
    await this.buscarUserCompanies();
    await this.generateData();
  },
};
</script>

<style scoped>
.lined-separator {
  border-left: 1px dashed #d9def1;
  width: 1px;
}

.btn-analytics {
  flex: 1 1 auto;
  background-color: #f4f7f9;
  color: var(--primary-color);
  border-radius: 10px;
  padding: 0.75rem 1rem;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
}

.btn-analytics.white {
  background-color: white;
}

.btn-analytics.all {
  background-color: #7b91b0;
  color: white;
  font-weight: 700;
}

.btn-analytics.all:hover,
.btn-analytics:hover {
  background-color: var(--primary-color);
  color: white;
}

.btn-analytics.selected{
    background-color: var(--primary-color);
    color: white;
    font-weight: 700;
}

/* Table */

.analytics-table-container {
  border-radius: 10px;
  /* overflow: auto; */
  border: 1px solid #f4f7f9;
}

.analytics-table thead {
  background-color: white;
  color: black;
}

.analytics-table tr td {
  transition: all 0.2s ease-in-out 0s;
}

.analytics-table tr:hover td {
  background-color: #f5f6f7;
}

.text-clip {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Placeholder */

.placeholder-set h4,
.placeholder-set p,
.placeholder-set span,
.placeholder-set svg {
  font-size: 0 !important;
  background-color: unset !important;
}

.placeholder-set svg {
  display: none;
}

.placeholder-set {
    display: flex;
    flex-direction: column;
    gap: 0.625rem
}

.circle-loading {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite linear;
}

@keyframes loading-circle {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.placeholder-loading {
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite linear;
    border-radius: 4px;
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.bar-loading {
  height: 100%;
  width: 15px;
  margin: 0 auto;
  border-radius: 100px;
  background-color: #f2f7ff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
}

.bar-loading .level {
  position: relative;
  pointer-events: none;
  background: #f0f0f0;
  border-radius: 100px;
  height: 30px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  min-height: 10px;
  height: 100%;
  animation: largeChartLine 2.5s infinite linear;
  transition: all 0.2s ease-in-out 0s;
}

@keyframes largeChartLine {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}

/* Placeholder gap */

.plgap-1 {
    grid-gap: 0.625rem
}

.plgap-2 {
    grid-gap: 1rem;
}

/* Placeholder width */

.plw-10 {
    width: 10%;
}

.plw-20 {
    width: 20%;
}

.plw-30 {
    width: 30%;
}

.plw-40 {
    width: 40%;
}

.plw-50 {
    width: 50%;
}

.plw-60 {
    width: 60%;
}

.plw-70 {
    width: 70%;
}

.plw-100 {
    width: 100%;
}

/* Placeholder height */

.plh-16 {
    height: 16px;
}

.plh-18 {
    height: 18px;
}

.plh-20 {
    height: 20px;
}

.plh-30 {
    height: 30px;
}

.plh-40 {
    height: 40px;
}

.plh-50 {
    height: 50px;
}
.btn-excel {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: 2px solid var(--accent-color);
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    flex: 0 0 50px;
    transition: var(--all-transition);
}

.btn-excel:hover {
  background: var(--accent-color);
}

</style>

<style>
.filter-btn,
.dropdown-menu-export .dropdown-btn {
    padding: 0.825rem 1.25rem !important;
}
</style>
