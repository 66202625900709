<template>
  <div :style="{ maxWidth: maxWidth, minWidth: minWidth }">
    <apexchart
      :width="chartWidth"
      type="donut"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: 'VDonutChart',
  props: {
    chartWidth: {
      type: String,
      default: '200px',
    },
    series: {
      type: Array,
      default: () => [44, 55, 41],
    },
    labels: {
      type: Array,
      default: () => ['label 1', 'label 2', 'label 3'],
    },
    colors: {
      type: Array,
      default: () => ['#104375', '#1963AC', '#268AEC'],
    },
    donutWidth: {
      type: String,
      default: '50%',
    },
    labelFormatter: {
      type: Function,
      default: (val, opts) => [
        opts.w.config.labels[opts.seriesIndex],
        `${val.toFixed(2)}%`,
      ],
    },
    showLegend: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: '',
    },
    minWidth: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      chartOptions: {
        plotOptions: {
          pie: {
            donut: {
              size: this.donutWidth,
            },
          },
        },
        dataLabels: {
          background: {
            enabled: true,
            foreColor: '#000',
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#fff',
            opacity: 1,
            dropShadow: {
              enabled: true,
            },
          },
          dropShadow: {
            enabled: false,
          },
          formatter: (val, opts) => this.labelFormatter(val, opts),
        },
        labels: this.labels,
        legend: {
          show: this.showLegend,
        },
        tooltip: {
          enabled: this.showTooltip,
        },
        colors: this.colors,
        stroke: {
          width: 0,
        },
      },
    };
  },
};
</script>
