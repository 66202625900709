<template>
  <div class="columns gap2 ai-gc">
    <div :class="showLegends ? 'span-8': 'span-12'">
      <div :class="[
        'ct-chart ct-perfect-fourth',
        chartName
      ]" @mouseover="hoverChart"></div>
    </div>
    <div class="span-4" v-if="showLegends">
      <div class="legends">
        <h5 class="text-light mb-3">Legendas do gráfico</h5>
        <ul class="chart-legends">
          <li
            v-for="(legend, index) in getLegends()"
            :key="legend.label"
            :class="[
              'flex ai-c jc-sb fgap2 pointer',
              activeLegends.find((legend) => String(legend) === String(index)) ? 'active' : ''
            ]"
            @mouseenter="zoomSvg(index)"
            @mouseleave="unzoomSvg()"
            @click="activeSvg(index); toggleListActive(index);"
          >
            <div class="flex ai-c fgap1">
              <span class="legend-indicator" :style="`background-color: ${legend.color};`">&nbsp;</span>
              <span>{{ legend.label }}</span>
            </div>
            <span class="bold">{{ legend.value }}</span>
          </li>
          <li class="flex ai-c jc-sb fgap2">
            <div class="flex ai-c fgap1">
              <span class="legend-indicator" :style="`background-color: #000000;`">&nbsp;</span>
              <span>Total</span>
            </div>
            <span class="bold">{{ getTotal() }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>
import Chartist from 'chartist';
import 'chartist/dist/chartist.min.css';

export default {
  name: 'Chartist',
  props: ['series', 'colors', 'showLegends', 'chartName'],
  data() {
    return {
      activeLegends: [],
    };
  },
  methods: {
    renderChart() {
      // eslint-disable-next-line prefer-const
      let chart = Chartist.Pie(`.${this.chartName}`, {
        series: this.series,
        labels: this.getLabels(),
      }, {
        donut: true,
        donutWidth: 50,
        donutSolid: false,
        startAngle: 270,
        showLabel: true,
        chartPadding: 20,
      });

      chart.on('draw', (data) => {
        if (data.type === 'slice') {
          if (this.colors[data.index]) {
            // eslint-disable-next-line no-underscore-dangle
            data.element._node.setAttribute('style', `stroke: ${this.colors[data.index]};`);
            // eslint-disable-next-line no-underscore-dangle
            data.element._node.classList.add(`slice-${[data.index]}`);
            // eslint-disable-next-line no-underscore-dangle
            data.element._node.setAttribute('data-slice', `${data.index}`);
          }
        }
        if (data.type === 'label') {
          // eslint-disable-next-line no-underscore-dangle
          data.element._node.classList.add(`label-${[data.index]}`);
          // eslint-disable-next-line no-underscore-dangle
          data.element._node.setAttribute('data-label', `${data.index}`);
        }
      });
    },
    getTotal() {
      let total = 0;
      this.series.map((item) => {
        total += item.value;
        return false;
      });
      return parseInt(total, 0);
    },
    getLegends() {
      const legends = [];
      this.series.map((item, index) => {
        legends.push({
          label: item.meta,
          value: item.value,
          color: this.colors[index],
        });
        return false;
      });
      return legends;
    },
    getLabels() {
      const labels = [];
      this.series.map((item) => {
        labels.push(`${parseFloat(((item.value / this.getTotal()) * 100).toFixed(2))}%`);
        return false;
      });
      return labels;
    },
    zoomSvg(index) {
      const svgs = document.querySelectorAll(`.${this.chartName} .ct-slice-donut`);
      for (let i = 0; i < svgs.length; i += 1) {
        const svg = svgs[i];
        if (!svg.classList.contains(`slice-${index}`)) {
          svg.classList.add('transparent');
          svg.classList.remove('active');
          svg.closest('svg').querySelector(`[data-label="${svg.dataset.slice}"]`).classList.remove('active');
        } else {
          svg.classList.remove('transparent');
          svg.classList.add('active');
          svg.closest('svg').querySelector(`[data-label="${svg.dataset.slice}"]`).classList.add('active');
        }
      }
    },
    unzoomSvg() {
      const svgs = document.querySelectorAll(`.${this.chartName} .ct-slice-donut`);
      for (let i = 0; i < svgs.length; i += 1) {
        const svg = svgs[i];
        svg.closest('svg').querySelector(`[data-label="${svg.dataset.slice}"]`).classList.remove('active');
        svg.classList.remove('transparent');
        svg.classList.remove('active');
      }
    },
    activeSvg(index) {
      const svg = document.querySelector(`.${this.chartName} .slice-${index}`);
      if (svg.classList.contains('activePersistent')) {
        svg.classList.remove('activePersistent');
        svg.closest('svg').querySelector(`.${this.chartName} [data-label="${svg.dataset.slice}"]`).classList.remove('activePersistent');
      } else {
        svg.classList.add('activePersistent');
        svg.closest('svg').querySelector(`.${this.chartName} [data-label="${svg.dataset.slice}"]`).classList.add('activePersistent');
      }
    },
    hoverChart() {
      const svgs = document.querySelectorAll(`.${this.chartName} .ct-slice-donut`);
      for (let i = 0; i < svgs.length; i += 1) {
        const svg = svgs[i];
        svg.addEventListener('mouseenter', () => {
          svg.classList.add('active');
          svg.closest('svg').querySelector(`.${this.chartName} [data-label="${svg.dataset.slice}"]`).classList.add('active');
        });
        svg.addEventListener('mouseleave', () => {
          svg.classList.remove('active');
          svg.closest('svg').querySelector(`.${this.chartName} [data-label="${svg.dataset.slice}"]`).classList.remove('active');
        });
      }
    },
    toggleListActive(index) {
      if (this.activeLegends.find((legend) => String(legend) === String(index))) {
        this.activeLegends = this.activeLegends.filter((legend) => String(legend) !== String(index));
      } else {
        this.activeLegends.push(String(index));
      }
    },
  },
  watch: {
    series: {
      deep: true,
      handler() {
        this.renderChart();
      },
    },
  },
};
</script>

<style>
  .ct-slice-donut,
  .ct-label {
    transition: all 0.1s ease-in-out 0s;
  }

  .ct-slice-donut.active,
  .ct-slice-donut.activePersistent {
    stroke-width: 90px !important;
  }

  .ct-slice-donut.transparent {
    opacity: 0.3;
  }

  .ct-label {
    font-weight: bold !important;
    font-size: 20px;
    fill: white !important;
    pointer-events: none;
    opacity: 0;
  }

  .ct-label.active,
  .ct-label.activePersistent {
    opacity: 1;
  }

  .chart-legends li {
    padding: 0.5rem;
    border-radius: var(--small-border-radius);
  }

  .chart-legends .legend-indicator {
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    border-radius: 50%;
  }

  .chart-legends li.active {
    background-color: var(--light-bg-color);
    font-weight: bold;
  }
</style>
