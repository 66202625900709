<template>
  <div class="input-taggable-body" ref="inputBody">
    <label class="bold" :for="name">{{ label }}</label>
    <div
      class="container-input-taggable flex fw-w"
      :class="{ 'ov-h': !showDropdown }"
      ref="inputContainer"
    >
      <input
        type="text"
        :name="name"
        :id="name"
        :placeholder="placeholder"
        :style="{ searchWidth: searchWidth }"
        v-model="search"
        @focus="detectDropdownPositionOnFocus"
        jsaction="paste:puy29d;"
        aria-autocomplete="both"
        aria-haspopup="false"
        autocapitalize="off"
        autocomplete="off"
        autocorrect="off"
        spellcheck="false"
      />

      <div class="flex fw-w fgap1" style="padding: 0.5rem">
        <div class="tag" v-for="(item, index) in value" :key="`tag-${Math.random()}-${index}`">
          <span @click.prevent="() => deselectValue(item)">
            <x-icon size="1.5rem" />
          </span>
          {{ options.find((opt) => opt.value == item)?.label ?? "TODOS" }}
        </div>
      </div>

      <div
        :class="`search-results ${position} ${showDropdown ? 'active' : ''}`"
        ref="searchResults"
      >
        <ul>
          <li
            v-for="item in filteredValues"
            :key="`tag-${Math.random()}-${item.value}`"
            :class="`${value.indexOf(item.value) >= 0 ? 'selected' : ''}`"
            @click.stop="() => selectValue(item.value)"
            @keydown.enter.prevent="() => selectValue(item.value)"
            @keydown.space.prevent="() => selectValue(item.value)"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import XIcon from '../Icons/XIcon.vue';

export default {
  name: 'VInputTaggable',
  components: {
    XIcon,
  },
  props: {
    value: {},
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    searchWidth: {
      type: String,
      default: 'auto',
    },
  },
  data() {
    return {
      position: 'bottom',
      search: '',
      timeout: null,
      timeoutSearch: null,
      showDropdown: false,
      positionDetected: false,
    };
  },
  computed: {
    filteredValues() {
      const reg = new RegExp(this.search, 'gi');
      return this.options.filter((opt) => opt.label.match(reg));
    },
  },
  methods: {
    selectValue(val) {
      if (this.value.indexOf(val) >= 0) {
        const newVal = this.value.filter((item) => item !== val);
        this.$emit('input', newVal);
      } else {
        this.$emit('input', [...this.value, val]);
      }
      this.search = '';
      this.showDropdown = false;
    },
    deselectValue(val) {
      this.$emit('input', this.value.filter((item) => item !== val));
    },
    detectDropdownPosition() {
      if (this.search.length > 0) {
        if (!this.positionDetected) {
          const dropdown = this.$refs.searchResults;
          const rect = dropdown.getBoundingClientRect();

          if (window.innerHeight - rect.bottom >= dropdown.clientHeight) {
            this.position = 'bottom';
          } else if (rect.top + 50 >= dropdown.clientHeight) {
            this.position = 'top';
          } else {
            this.position = 'bottom';
          }
          this.positionDetected = true;
        }
        this.showDropdown = true;
      } else {
        this.showDropdown = false;
      }
    },
    detectDropdownPositionOnFocus() {
      if (!this.positionDetected) {
        const dropdown = this.$refs.searchResults;
        const rect = dropdown.getBoundingClientRect();

        if (window.innerHeight - rect.bottom >= dropdown.clientHeight) {
          this.position = 'bottom';
        } else if (rect.top + 50 >= dropdown.clientHeight) {
          this.position = 'top';
        } else {
          this.position = 'bottom';
        }
        this.positionDetected = true;
      }
      this.showDropdown = true;
    },
    onBlur() {
      console.log('blur');
    },
    checkClickOutside(e) {
      if (e.target.closest('.input-taggable-body') !== this.$refs.inputBody) {
        this.showDropdown = false;
      }
      // if (!e.target.closest('.input-taggable-body')) {
      // }
    },
    checkEscape(e) {
      if (e.key === 'Escape') {
        this.showDropdown = false;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.checkClickOutside);
    window.addEventListener('keyup', this.checkEscape);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.checkClickOutside);
    window.removeEventListener('keyup', this.checkEscape);
  },
  watch: {
    search() {
      this.detectDropdownPosition();
    },
  },
};
</script>

<style scoped>
.container-input-taggable {
  position: relative;
  background-color: #f4f7f9;
  border-radius: 15px;
}

.container-input-taggable input {
  width: auto;
  border: 0;
  background-color: transparent;
}

.container-input-taggable input:hover {
  border: 0;
}

.container-input-taggable .tag {
  background-color: white;
  border-radius: 10px;
  padding: 0.25rem 0.75rem;
  color: black;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.container-input-taggable .tag span {
  cursor: pointer;
}

.container-input-taggable .search-results {
  transition: all 0.2s ease-in-out 0s;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
  min-width: 300px;
  max-width: 300px;
  border-radius: 10px;
  background-color: white;
  max-height: 300px;
  overflow: auto;
  box-shadow: 3px 3px 10px rgb(0 0 0 / 0.05);
  border: 1px solid #f4f7f9;
  opacity: 0;
  visibility: hidden;
}

.container-input-taggable .search-results.active {
  opacity: 1;
  visibility: visible;
}

.container-input-taggable .search-results.top {
  bottom: calc(100% + 10px);
}

.container-input-taggable .search-results.bottom {
  top: 60px;
}

.container-input-taggable .search-results ul li {
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out 0s;
}

.container-input-taggable .search-results ul li:hover {
  border: 1px solid #e2e2e2;
}

.container-input-taggable .search-results ul li:first-of-type {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.container-input-taggable .search-results ul li:last-of-type {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.container-input-taggable .search-results ul li.selected {
  background-color: var(--primary-color);
  color: white;
}
</style>
