<template>
  <aside class="main-sidebar flex fd-c jc-sb fgap2">
    <div class="flex fd-c fgap2">
      <div class="text-center p-1">
        <router-link :to="{ name: 'Home' }">
          <img src="@/assets/img/logo.png" alt="Prime Internacional" />
        </router-link>
      </div>

      <ul class="navigation">
        <p class="text-center text-light text-sm">Menu</p>
        <li>
          <router-link :to="{ name: 'Home' }">
            <span class="material-symbols-outlined" style="font-size: 3rem">
              dashboard
            </span>
          </router-link>
          <div class="nav-tooltip">
            <span class="text-medium">Dashboard</span>
          </div>
        </li>
        <li>
          <router-link :to="{ name: 'Processes' }">
            <span class="material-symbols-outlined" style="font-size: 3rem">
              conveyor_belt
            </span>
          </router-link>
          <div class="nav-tooltip">
            <span class="text-medium">Processos</span>
          </div>
        </li>
        <li>
          <router-link :to="{ name: 'ProcessesReport' }">
            <span class="material-symbols-outlined" style="font-size: 3rem">
              lab_profile
            </span>
          </router-link>
          <div class="nav-tooltip">
            <span class="text-medium">Relatórios</span>
          </div>
        </li>
        <!-- <li>
          <router-link :to="{ name: 'FinanceiroPrevisaoNumerario' }">
            <span class="material-symbols-outlined" style="font-size: 3rem;">
              currency_exchange
            </span>
          </router-link>
          <div class="nav-tooltip">
            <span class="text-medium">Calendário Financeiro</span>
          </div>
        </li> -->
        <li>
          <router-link :to="{ name: 'FinanceiroPrevisaoNumerarioNovo' }">
            <span class="material-symbols-outlined" style="font-size: 3rem;">
              currency_exchange
            </span>
          </router-link>
          <div class="nav-tooltip">
            <span class="text-medium">Calendário Financeiro</span>
          </div>
        </li>
        <li>
          <router-link :to="{ name: 'OperacionalCalendario' }">
            <span class="material-symbols-outlined" style="font-size: 3rem;">
              calendar_clock
            </span>
          </router-link>
          <div class="nav-tooltip">
            <span class="text-medium">Calendário Operacional</span>
          </div>
        </li>
        <li v-if="showAnalytics">
          <router-link :to="{ name: 'Analytics' }">
            <span class="material-symbols-outlined" style="font-size: 3rem;">
              insert_chart
            </span>
          </router-link>
          <div class="nav-tooltip">
            <span class="text-medium">Analytics</span>
          </div>
        </li>
      </ul>
    </div>

    <div class="flex ai-c jc-c text-center mb-1">
      <a href="#" @click.prevent="logout">
        <span class="material-symbols-outlined" style="font-size: 3rem">
          logout
        </span>
      </a>
    </div>
  </aside>
</template>

<script>
import { mapActions } from 'vuex';
import api from '@/services/api';

export default {
  name: 'Sidebar',
  methods: {
    ...mapActions('auth', ['logout', 'user', 'updateUser']),
    ...mapActions(["toggleLoading"]),
    async buscarUserCompanies() {
      try {
        await this.toggleLoading(true);

        const listCompaniesIds = [7, 8, 3, 82, 2082, 166, 2378, 102, 2397, 2517, 119, 100, 24, 154];
        let listCompanies = JSON.parse(localStorage.getItem('@GSCMX-PC:companies')) || [];

        if (!listCompanies.length) {
            if (this.user.companies) {
                listCompanies = this.user.companies.filter(company => company.customer && !company.customBroker);
            } else {
                const userResponse = await api.get('/credential');
                this.updateUser(userResponse.data.data);
                listCompanies = userResponse.data.data.person.companies.filter(company => company.customer && !company.customBroker);
            }
        }

        this.showAnalytics = listCompanies.some(company => listCompaniesIds.includes(company.id));
      } catch (error) {
        console.error(error);
      } finally {
        this.toggleLoading(false);
      }
    },
  },
  data() {
    return {
        showAnalytics: false
    };
  },
  mounted() {
    this.buscarUserCompanies();
  }
};
</script>

<style scoped>
.main-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 80px;
  background-color: var(--white-color);
  z-index: 999999;
}

.main-sidebar .navigation a {
  display: block;
  text-align: center;
  padding: 0.75rem 1rem;
  opacity: 0.6;
  position: relative;
  transition: var(--all-transition);
}

.main-sidebar .navigation a:hover {
  color: var(--primary-color);
}

.main-sidebar .navigation a::before {
  content: '';
  display: block;
  position: absolute;
  top: 0.75rem;
  bottom: 0.75rem;
  left: 0;
  width: 2px;
  background-color: var(--primary-color);
  transition: var(--all-transition);
  opacity: 0;
}

.main-sidebar .navigation a.router-link-exact-active,
.main-sidebar .navigation a.router-link-exact-active::before,
.main-sidebar .navigation a:hover {
  opacity: 1;
}

.navigation li {
  position: relative;
}

.navigation li .nav-tooltip {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 90%;
  transform: translate3d(0, -50%, 0);
  background-color: white;
  z-index: 99;
  pointer-events: none;
  padding: 0.75rem;
  border-radius: var(--medium-border-radius);
  color: var(--primary-color);
  box-shadow: var(--box-shadow);
  border: 1px solid var(--primary-color);
  transition: var(--all-transition);
}

.navigation li .nav-tooltip::after,
.navigation li .nav-tooltip::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  border-width: 5px 10px 5px 0;
  border-color: transparent white transparent transparent;
  top: 50%;
  left: -10px;
  transform: translateY(-50%);
}

.navigation li .nav-tooltip::before {
  border-width: 6px 11px 6px 0;
  left: -11px;
  border-color: transparent var(--primary-color) transparent transparent;
}

.navigation li:hover .nav-tooltip {
  left: calc(100% + 10px);
  opacity: 1;
  visibility: visible;
}
</style>
