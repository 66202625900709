<template>
  <div>
    <div class="p-2 flex ai-c jc-sb fgap1">
      <p>Total de processos por período</p>
      <button v-if="getLength() > 12" class="btn solid primary small" @click.prevent="modalOpen = true">
        Visualização expandida
      </button>
    </div>

    <div class="large-chart-line">
      <ul class="pv-2 flex w-full large-chart-line-series" :style="{ minHeight }">
        <li :style="{ flex: `0 1 ${100 / getLength()}%` }" class="flex fd-c">
          <ul class="flex fd-c fgap1 w-full h-full jc-sb text-center">
            <div class="text-bold mb-1 ph-1 pb-1 text-nowrap" style="border-bottom: 1px solid #e2e2e2;">Total</div>

            <li v-for="(item, index) in getLeftLabels()" :key="`${item}-${index}`" class="text-center">
              {{ item }}
            </li>
          </ul>
        </li>

        <li
          class="flex fd-c"
          :style="{ flex: `0 1 ${100 / getLength()}%` }"
          v-for="(item, index) in getSeries()"
          :key="`${item}-${index * 10}`"
          :class="{ 'chart-opacity': item?.opacity }"
        >
          <div class="text-center text-bold mb-2 pb-1 text-nowrap ph-1"  style="border-bottom: 1px solid #e2e2e2;">
            {{item.label}}
          </div>
          <div
            :class="{
              'large-chart-line-bar': true,
              blue: item.value != highestValue() && item.value != lowestValue(),
              red: item.value == lowestValue(),
              green: item.value == highestValue(),
              [modifyClass]: true,
              'chart-opacity-color': item?.opacity,
            }"
          >
            <div
              class="level"
              :style="`--percentage: ${(item.value / ceiledHighestValue()) * 100}%`"
            ></div>
            <span>{{ item?.opacity ? '' : item.value }}</span>
          </div>
        </li>
      </ul>
    </div>

    <Modal :handler="modalOpen" @request-close="modalOpen = false">
      <template #head>
        Visão expandida do gráfico
      </template>

      <template #content>
        <div class="flex fd-c fgap2">
          <div v-for="(series, index) in getSlicedSeries()" :key="index * 23" class="large-chart-line">
            <ul class="pv-2 flex w-full large-chart-line-series" :style="{ minHeight }">
              <li :style="{ flex: `0 1 ${100 / getLength(series)}%` }" class="flex fd-c">
                <ul class="flex fd-c fgap1 w-full h-full jc-sb text-center">
                  <div class="text-medium mb-1 ph-1 pb-1 text-nowrap" style="border-bottom: 1px solid #e2e2e2;">Total</div>

                  <li v-for="(item, index) in getLeftLabels(series)" :key="`${item}-${index * 32}`" class="text-center">
                    {{ item }}
                  </li>
                </ul>
              </li>

              <li
                class="flex fd-c"
                :style="{ flex: `0 1 ${100 / getLength(series)}%` }"
                v-for="(item, index) in series"
                :key="`${item}-${index * 19}`"
              >
                <div class="text-center text-medium mb-2 pb-1 text-nowrap ph-1"  style="border-bottom: 1px solid #e2e2e2;">
                  {{item.label}}
                </div>
                <div
                  :class="{
                    'large-chart-line-bar': true,
                    blue: item.value != highestValue(series) && item.value != lowestValue(series),
                    red: item.value == lowestValue(series),
                    green: item.value == highestValue(series) && item.value != lowestValue(series),
                    [modifyClass]: true,
                  }"
                >
                  <div
                    class="level"
                    :style="`--percentage: ${(item.value / ceiledHighestValue(series)) * 100}%`"
                  ></div>
                  <span>{{ item.value }}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '../Modal.vue';

export default
{
  name: 'VLargeBarChart',
  components: { Modal },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    minHeight: {
      type: String,
      default: '400px',
    },
    modifyClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fallbackSeries: [],
      modalOpen: false,
    };
  },
  methods: {
    getSeries() {
      if (this.series.length > 0) {
        return this.series;
      }
      return this.fallbackSeries;
    },
    getLength(series = null) {
      if (series != null) {
        return series.length;
      }

      if (this.series.length > 0) {
        return this.series.length;
      }
      return this.fallbackSeries.length;
    },
    getHighestValue(series = null) {
      let values = [];

      values = this.fallbackSeries.map((serie) => serie.value);

      if (this.series.length > 0) {
        values = this.series.map((serie) => serie.value);
      }

      if (series != null) {
        values = series.map((serie) => serie.value);
      }

      return Math.max(...values);
    },
    getLeftLabels(series = null) {
        let highestValue = this.ceiledHighestValue();

        if (series != null) {
            highestValue = this.ceiledHighestValue(series);
        }

        const maxDivisor = Math.ceil(highestValue / 10) * 10;
        const divisors = [];

        if(maxDivisor == 10) {
            return [10,9,8,7,6,5,4,3,2,1,0];
        }

        for (let i = maxDivisor; i >= 10; i -= 10) {
            divisors.push(i);
        }

        let highestDivisor = 0;
        let calcDivisor = 0;
        const labels = [];

        for (let i = 0; i < divisors.length; i += 1) {
            const divisor = divisors[i];

            if (highestDivisor > 0 || calcDivisor > 0) {
            break;
            }

            if (highestValue / divisor >= 1) {
            highestDivisor = divisor;
            calcDivisor = 10;
            break;
            }
        }

        let calcNumber = highestDivisor;

        labels.push(calcNumber);
        while (calcNumber > 0) {
            labels.push(calcNumber - calcDivisor);
            calcNumber -= calcDivisor;
        }

        return labels;
    },
    // getLeftLabels(series = null) {
    //   let highestValue = this.ceiledHighestValue();

    //   if (series != null) {
    //     highestValue = this.ceiledHighestValue(series);
    //   }

    //   const divisors = [1000000, 100000, 10000, 1000, 100, 10, 1];
    //   let highestDivisor = 0;
    //   let calcDivisor = 0;
    //   const labels = [];

    //   for (let i = 0; i < divisors.length; i += 1) {
    //     const divisor = divisors[i];

    //     if (highestDivisor > 0 || calcDivisor > 0) {
    //       break;
    //     }

    //     if (highestValue / divisor >= 1) {
    //       highestDivisor = divisor;
    //       calcDivisor = divisors[i + 1] ?? 1;
    //       break;
    //     }
    //   }

    //   let calcNumber = highestDivisor;

    //   labels.push(calcNumber);
    //   while (calcNumber > 0) {
    //     labels.push(calcNumber - calcDivisor);
    //     calcNumber -= calcDivisor;
    //   }

    //   return labels;
    // },
    ceilHighestValue(series = null) {
      if (series != null) {
        return Math.ceil(this.getHighestValue(series) / 10) * 10;
      }
      return Math.ceil(this.getHighestValue() / 10) * 10;
    },
    ceiledHighestValue(series = null) {
      if (series != null) {
        return Math.ceil(this.getHighestValue(series) / 10) * 10;
      }
      return Math.ceil(this.getHighestValue() / 10) * 10;
    },
    lowestValue(series = null) {
      let values = this.fallbackSeries.map((serie) => serie.value);

      if (this.series.length > 0) {
        values = this.series.map((serie) => serie.value);
      }

      if (series != null) {
        values = series.map((serie) => serie.value);
      }

      return Math.min(...values);
    },
    highestValue(series = null) {
      let values = this.fallbackSeries.map((serie) => serie.value);

      if (this.series.length > 0) {
        values = this.series.map((serie) => serie.value);
      }

      if (series != null) {
        values = series.map((serie) => serie.value);
      }

      return Math.max(...values);
    },
    getSlicedSeries() {
      const inSeries = this.series.length > 0 ? this.series : this.fallbackSeries;
      const result = {};

      for (let i = 0; i < inSeries.length; i += 12) {
        result[i > 0 ? i / 12 : i] = [...inSeries].splice(i, 12);
      }

      return result;
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style scoped>
.large-chart-line {
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
}

.large-chart-line-series {
  overflow: auto;
}

.large-chart-line-bar {
  height: 100%;
  width: 50px;
  margin: 0 auto;
  border-radius: 100px;
  background-color: #f2f7ff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
}

.large-chart-line-bar.modify{
  width: 25px;
}

.large-chart-line-bar .level {
  position: relative;
  pointer-events: none;
  background: red;
  border-radius: 100px;
  height: 30px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  min-height: 60px;
  height: var(--percentage);
  animation: largeChartLine 0.6s cubic-bezier(0.6, 0.1, 1, 0.8);
  transition: all 0.2s ease-in-out 0s;
}

.large-chart-line-bar.blue .level {
  background: linear-gradient(to top, #114374 40%, #11437499);
}

.large-chart-line-bar.red .level {
  background: linear-gradient(to top, #f26767 40%, #f2676799);
}
.large-chart-line-bar.green .level {
  background: linear-gradient(to top, #137700 40%, #13770099);
}

.large-chart-line-bar span {
  font-weight: bold;
  color: white;
  display: block;
  position: relative;
  z-index: 2;
}

.chart-opacity {
  opacity: 0.3;
}

.chart-opacity-color .level,
.chart-opacity-color {
    background: linear-gradient(to top, #114374 40%, #11437499) !important
}

@keyframes largeChartLine {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
</style>
