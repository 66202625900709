var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-card",class:{
    white: _vm.theme === 'white',
    light: _vm.theme === 'light',
    shadow: _vm.shadow,
    bordered: _vm.bordered,
    'no-padding': _vm.noPadding,
    'mouse-pointer': _vm.mousePointer === true
  },on:{"click":function($event){return _vm.$emit('clickCard')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }