<template>
    <div class="flex ai-c jc-gsa tooltip-icon" :style="{ 'margin-left': marginLeft }">
        <InfoIcon :size="size" />
        <div class="tooltip">
            <div class="flex fd-c gap1 ai-gc">
                <span class="text-medium">{{ dateStart }} - {{ dateEnd }}</span>
                <span class="text-medium">{{ title }} {{ description }}</span>
                <!-- <span class="text-medium">{{ title }}: {{ description }}</span> -->
            </div>
        </div>
    </div>
</template>

<script>
import { InfoIcon } from '@/components/Icons';

export default {
    name: 'VTooltip',
    components: { InfoIcon },
    props: {
        size: {
            type: String,
            default: '1.5rem',
        },
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        dateStart: {
            type: String,
            default: '',
        },
        dateEnd: {
            type: String,
            default: '',
        },
        marginLeft: {
            type: String,
            default: '0.3rem',
        }
    },
};
</script>

<style scoped>
.tooltip-icon {
    position: relative;
    cursor: pointer;
}

.tooltip {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    white-space: nowrap;
    top: calc(100% + 50px);
    background-color: white;
    z-index: 9999;
    pointer-events: none;
    padding: 0.75rem;
    border-radius: var(--medium-border-radius);
    color: var(--primary-color);
    box-shadow: var(--box-shadow);
    border: 1px solid var(--primary-color);
    transition: var(--all-transition);
}

.tooltip::before,
.tooltip::after{
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    border-style: solid;
    border-width: 0 6px 11px 6px;
    border-color: transparent transparent white transparent;
    top: -9px;
    left: 50%;
    transform: translateX(-50%);
}

.tooltip::before {
    border-width: 0 6px 11px 6px;
    top: -11px;
    left: 50%;
    border-color: transparent transparent var(--primary-color) transparent;
}

.tooltip-icon:hover .tooltip {
    top: calc(100% + 15px);
    /* transform: translateY(-50%); */
    opacity: 1;
    visibility: visible;
}
</style>
