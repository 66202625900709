<template>
  <div :class="['styled-select', multiple ? 'multiple' : '', 'relative', useIcon == true ? 'has-icon' : '']">
    <div class="select-icon" v-if="useIcon">
      <slot name="icon" class="select-icon"></slot>
    </div>
    <ul class="select-list relative" :class="classSelectList" v-if="useDate == false" @click.self.prevent="openedSelect = !openedSelect">

      <p class="title" @click.self.prevent="openedSelect = !openedSelect">{{ title }}</p>

      <span class="selected-option" @click.self.prevent="openedSelect = !openedSelect" v-if="!multiple">
        {{ Object.entries(selectedOption).length > 0 ? selectedOption.label : 'Selecione' }}
      </span>

      <span class="selected-option" :class="{'multiple': multiple}" @click.self.prevent="openedSelect = !openedSelect" v-if="multiple">
        {{ selectedOptions.length > 0 ? selectedOptions.map(option => option.label).join(', ') : 'TODAS' }}
      </span>

      <transition name="select" mode="out-in">
        <template>
          <div class="options" v-if="openedSelect && !multiple">
            <div class="select-search">
              <input type="search" name="search" v-model="search" placeholder="Pesquisar..."/>
            </div>
            <div class="options-container">
              <li
                :class="value === option.value ? 'active' : ''"
                v-for="option in filteredOptions"
                :key="getValueOption(option)"
                @click="() => {
                  $emit('input', getValueOption(option));
                  selectedOption = option;
                  openedSelect = false;
                }"
              >
                {{ option.label }}
              </li>
            </div>
          </div>

          <div class="options" v-if="openedSelect && multiple">
            <div class="select-search">
              <input type="search" name="search" v-model="search" placeholder="Pesquisar..."/>
            </div>
            <div class="options-container">
              <li
                :class="selectedOptions.some(opt => opt.value === option.value) ? 'active' : ''"
                v-for="option in filteredOptions"
                :key="getValueOption(option)"
                @click="() => {
                  $emit('input', getMultipleValueOptions(option));
                }"
              >
                {{ option.label }}
              </li>
            </div>
          </div>
        </template>
      </transition>
    </ul>

    <div class="select-list date" v-else @click="openPicker">
      <label>
        <p class="title">{{ title }}</p>
        <DatePicker
          ref="picker"
          class="home-datepicker"
          @change="$emit('input', $event)"
          format="DD/MM/YYYY"
          value-type="format"
          v-model="compValue"
          lang="pt-br"
          placeholder=""

        />
        <!-- :range="true" -->
      </label>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  name: 'Select',
  components: {
    DatePicker,
  },
  created() {
    if (this.multiple) {
      const optionsValues = this.options.map((item) => item.value);
      this.selectedOptions = optionsValues.filter((item) => this.value.includes(item));
    } else {
      const [selected] = this.options.filter((option) => option.value === this.value);
      if (selected) {
        this.selectedOption = selected;
      }
    }
  },
  data() {
    return {
      selectedOption: {},
      selectedOptions: [],
      openedSelect: false,
      search: '',
    };
  },
  methods: {
    openPicker() {
      // console.log(this.$refs.picker);
      // console.log(this.$refs.pickershowPopup);
      this.$refs.picker.showPopup();
    },
    getValueOption(option) {
      if (this.valueIsObject) {
        return option;
      }

      return option.value ? option.value : option.attribute;
    },
    getMultipleValueOptions(option) {
      if (this.selectedOptions.find((opt) => opt.value === option.value)) {
        this.selectedOptions = this.selectedOptions.filter((opt) => opt.value !== option.value);
      } else {
        this.selectedOptions = [
          ...this.selectedOptions,
          { value: option.value, label: option.label },
        ];
      }
      return this.selectedOptions;
    },
  },
  mounted() {
    document.addEventListener('click', (event) => {
      if (!event.target.closest('.styled-select')) {
        this.openedSelect = false;
      }
    });
  },
  computed: {
    filteredOptions() {
      const reg = new RegExp(this.search, 'gi');
      if (this.search.length > 0) {
        return this.options.filter((opt) => reg.test(opt.label));
      }

      return this.options;
    },
    compValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        return this.$emit('blur', newValue);
        // return this.$emit('input', newValue);
      },
    },
  },
  watch: {
    clearSelect() {
      if (this.clearSelect) {
        this.selectedOptions = [];
      }
    },
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    useIcon: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    useDate: {
      type: Boolean,
      default: false,
    },
    valueIsObject: {
      type: Boolean,
      default: false,
    },
    classSelectList: {
      type: String,
      default: '',
    },
    clearSelect: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
/* .styled-select {
  z-index: 999;
} */

.styled-select .select-icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
}

.styled-select .select-list {
  border: var(--small-border-width) solid var(--primary-color);
  height: 50px;
  border-radius: var(--round-border-radius);
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 0.3rem 3rem 0.3rem 1.5rem;
  background: url("data:image/svg+xml;charset=utf8, %3Csvg width='24px' height='24px' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.75 9.5L12.75 15.5L18.75 9.5' fill='currentColor'/%3E%3C/svg%3E") calc(100% - 1rem) center / 24px no-repeat;
}

.styled-select.has-icon .select-list {
  padding-left: 50px;
}

.styled-select .select-list .title {
  font-weight: 700;
  font-size: 0.8rem;
  color: var(--primary-color);
}

.styled-select .select-list .selected-option {
  display: block;
  height: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  width: 140px;
}
.styled-select.multiple{
  width: 100%;
}

.styled-select .select-list .selected-option.multiple {
  width: 100%;
}

.styled-select .options .options-container {
  max-height: 200px;
  overflow: auto;
}

.styled-select .options {
  position: absolute;
  top: 105%;
  left: 0;
  right: 0;
  z-index: 99999;
  background-color: var(--white-color);
  border-radius: var(--medium-border-radius);
  border: var(--input-border);
  overflow: hidden;
  min-width: 240px;
}

.styled-select .options li {
  font-size: 1rem;
  padding: 0.6rem;
  transition: var(--all-transition);
}

.styled-select .options li:hover,
.styled-select .options li.active {
  background-color: var(--primary-color);
  color: var(--white-color);
}

.select-enter-active,
.select-leave-active {
  transition: var(--all-transition);
}

.select-enter,
.select-leave-to {
  opacity: 0;
  transform: translate3d(0, -30px, 0);
}

.select-enter-to,
.select-leave {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: var(--all-transition);
}

.invisible-date {
  position: absolute;
  inset: 0;
  opacity: 0 !important;;
}

.select-search {
  padding: 10px;
  border-bottom: 1px solid #e2e2e2;
}

.select-search input {
  height: 40px;
}

.select-list.date input {
  height: 20px;
  border: 0 !important;
  padding: 0;
  box-shadow: none;
  border-radius: 0;
  font-size: 14px;
  color: black;
}

.select-list.date .mx-icon-calendar {
  display: none;
}

.home-datepicker .mx-input-append {
  display: none;
}

.home-datepicker .mx-clear-wrapper {
  padding: 0px
}

.styled-select .mx-datepicker-popup {
    left: 0 !important;
}
</style>
