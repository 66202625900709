<template>
  <div class="horizontal-timeline">
    <div class="flex">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="timeline-item-value"
        :style="`--width: ${100 / items.length}%`"
      >
        <strong>{{ item.value }}</strong> Dias
      </div>
    </div>

    <div class="flex relative">
      <div class="timeline-dots"></div>
      <div
        v-for="(item, index) in items"
        :key="index"
        class="timeline-item-title"
        :style="`--width: ${100 / items.length}%`"
      >
        <span>
          {{ item.title }}
        </span>
      </div>
    </div>

    <div class="flex">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="timeline-item-description"
        :style="`--width: ${100 / items.length}%`"
      >
        <div v-html="item.description"></div>
      </div>
    </div>
  </div>
</template>

<script>
const defaultItems = [];

export default {
  name: 'VHorizontalTimeline',
  props: {
    items: {
      type: Array,
      default: () => defaultItems,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.timeline-item-description p {
  color: #737b8b;
  text-align: center;
}
</style>

<style scoped>
.horizontal-timeline {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.timeline-item-value {
  font-size: 1.5rem;
}

.timeline-item-description,
.timeline-item-title,
.timeline-item-value {
  flex: 0 0 var(--width);
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
  z-index: 5;
}

.timeline-item-title span {
  background-color: var(--primary-color);
  color: white;
  padding: 0.75rem 1rem;
  width: 65%;
  flex: 0 0 65%;
  text-align: center;
  word-break: break-all;
  border-radius: 200px;
  font-weight: 600;
}

.timeline-dots {
  position: absolute;
  height: 2px;
  width: 100%;
  inset: 0;
  margin: auto 0;
  background-image: url("data:image/svg+xml,%3Csvg width='100%' height='2' viewBox='0 0 885 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.52975 1.00586L884 1.00042' stroke='%2309233D' stroke-width='2' stroke-linecap='round' stroke-dasharray='1 8'/%3E%3C/svg%3E");
}

.timeline-dots::after,
.timeline-dots::before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-color: var(--primary-color);
  border-radius: 50%;
  position: absolute;
  top: -7px;
}

.timeline-dots::before {
  left: 0;
}

.timeline-dots::after {
  right: 0;
}
</style>
