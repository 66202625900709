<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="lucide lucide-move-up"
  >
    <path d="M8 6L12 2L16 6" />
    <path d="M12 2V22" />
  </svg>
</template>

<script>
export default {
  name: 'MoveUpIcon',
  props: {
    size: {
      type: String,
      default: '1.5rem',
    },
  },
};
</script>
