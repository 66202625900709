import moment from 'moment';
import router from '@/router';

const store = {
  namespaced: true,
  state: {
    user: {},
  },
  mutations: {
    UPDATE_USER(state, payload) {
      // eslint-disable-next-line no-param-reassign
      payload.hiringDate = moment(payload.hiringDate).format('YYYY-MM-DD');
      state.user = payload.person;
      
      localStorage.removeItem('@GSCMX-PC:user');
      if (payload?.person) {
        const userObject = { 
          id: payload.person.id, 
          name: payload.person.name, 
          email: payload.person.email, 
          photo: payload.person.photo 
        };
        localStorage.setItem('@GSCMX-PC:user', JSON.stringify(userObject));
      }

      localStorage.removeItem('@GSCMX-PC:companies');
      if (payload?.person?.companies) {
        const filteredCompanies = payload.person.companies
        .filter((company) => company.customer && !company.customBroker)
        .map((company) => ({ id: company.id, name: company.name.toUpperCase() }));
        localStorage.setItem('@GSCMX-PC:companies', JSON.stringify(filteredCompanies));
      }
     
    },
  },
  actions: {
    updateUser(context, payload) {
      context.commit('UPDATE_USER', payload);
    },
    logout(context) {
      localStorage.removeItem('@GSCMX-PC:access_token');
      localStorage.removeItem('@GSCMX-PC:refresh_token');
      localStorage.removeItem('@GSCMX-PC:bi_token');
      localStorage.removeItem('@GSCMX-PC:user');
      localStorage.removeItem('@GSCMX-PC:companies');
      context.commit('UPDATE_USER', {});
      router.push('/login');
    },
  },
};

export default store;
