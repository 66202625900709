// import Vue from 'vue';
import axios from 'axios';
import router from '../router';

const { VUE_APP_API_BI_HOST: host } = process.env;

const apiBi = axios.create({
  baseURL: `${host}`,
});

// apiBi.defaults.headers.common['App-Source'] = 'WEBPC';

apiBi.interceptors.request.use(async (config) => {
  if (localStorage.getItem('@GSCMX-PC:bi_token')) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      token: localStorage.getItem('@GSCMX-PC:bi_token'),
    };
  }

  return config;
}, (error) => {
  Promise.reject(error);
});

apiBi.interceptors.response.use((response) => {
  if (response.data.auth_data && response.data.auth_data.token) {
    localStorage.setItem('@GSCMX-PC:bi_token', response.data.auth_data.token);
  }
  return response;
});

apiBi.interceptors.response.use((response) => response, (error) => {
  console.log('erroAPIBI: ', error?.response);
  if (error?.response?.status === 400) {
    // Vue.$toast.error(error.response.data.error_description);
    throw new Error(error.response.data.error_description);
  }
  if (error?.response?.status === 401) {
    localStorage.removeItem('@GSCMX-PC:bi_token');
    localStorage.removeItem('@GSCMX-PC:access_token');
    localStorage.removeItem('@GSCMX-PC:refresh_token');
    router.push({ name: 'Login' });
  }
  throw new Error(error);
});

export default apiBi;
