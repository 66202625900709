export const OPERATIONAL_WAY_OF_TRANSPORTE = [
  {
    value: 'MARITIMA',
    label: 'Marítima',
  },
  {
    value: 'FLUVIAL',
    label: 'Fluvial',
  },
  {
    value: 'LACUSTRE',
    label: 'Lacustre',
  },
  {
    value: 'AEREA',
    label: 'Áerea',
  },
  {
    value: 'POSTAL',
    label: 'Postal',
  },
  {
    value: 'FERROVIARIA',
    label: 'Ferroviária',
  },
  {
    value: 'RODOVIARIA',
    label: 'Rodoviária',
  },
  {
    value: 'TUBO_CONDUTO',
    label: 'Tubo Conduto',
  },
  {
    value: 'MEIOS_PROPRIOS',
    label: 'Meios Próprios',
  },
  {
    value: 'ENTRADA_FICTA',
    label: 'Entrada Ficta',
  },
];

export const MONEY_LABELS = {
  dolar: {
    name: "Dólar",
    symbol: "U$",
    label: "USD",
  },
  euro: {
    name: "Euro",
    symbol: "€",
    label: "EUR",
  },
  libra: {
    name: "Libra",
    symbol: "£",
    label: "GBP",
  },
  real: {
    name: "Real",
    symbol: "R$",
    label: "BRL",
  },
};

export const COUNTRY_CODES_SIGLA = {
  "13": "af",
  "15": "ax",
  "17": "al",
  "23": "de",
  "31": "bf",
  "37": "ad",
  "40": "ao",
  "41": "ai",
  "42": "aq",
  "43": "ag",
  "53": "sa",
  "59": "dz",
  "63": "ar",
  "64": "am",
  "65": "aw",
  "69": "au",
  "72": "at",
  "73": "az",
  "77": "bs",
  "80": "bh",
  "81": "bd",
  "83": "bb",
  "85": "by",
  "87": "be",
  "88": "bz",
  "90": "bm",
  "93": "mm",
  "97": "bo",
  "98": "ba",
  "99": "bq",
  "101": "bw",
  "102": "bv",
  "105": "br",
  "108": "bn",
  "111": "bg",
  "115": "bi",
  "119": "bt",
  "127": "cv",
  "137": "ky",
  "141": "kh",
  "145": "cm",
  "149": "ca",
  "153": "kz",
  "154": "qa",
  "158": "cl",
  "160": "cn",
  "161": "tw",
  "163": "cy",
  "165": "cc",
  "169": "co",
  "173": "km",
  "177": "cg",
  "183": "ck",
  "187": "kp",
  "190": "kr",
  "193": "ci",
  "195": "hr",
  "196": "cr",
  "198": "kw",
  "199": "cu",
  "200": "cw",
  "229": "bj",
  "232": "dk",
  "235": "dm",
  "239": "ec",
  "240": "eg",
  "243": "er",
  "244": "ae",
  "245": "es",
  "246": "si",
  "247": "sk",
  "249": "us",
  "251": "ee",
  "253": "et",
  "255": "fk",
  "259": "fo",
  "267": "ph",
  "271": "fi",
  "275": "fr",
  "281": "ga",
  "285": "gm",
  "289": "gh",
  "291": "ge",
  "292": "gs",
  "293": "gi",
  "297": "gd",
  "301": "gr",
  "305": "gl",
  "309": "gp",
  "313": "gu",
  "317": "gt",
  "321": "gg",
  "325": "gf",
  "329": "gn",
  "334": "gw",
  "331": "gq",
  "334": "gw",
  "337": "gy",
  "341": "ht",
  "343": "hm",
  "345": "hn",
  "351": "hk",
  "355": "hu",
  "357": "ye",
  "359": "im",
  "361": "in",
  "365": "id",
  "369": "iq",
  "372": "ir",
  "375": "ie",
  "379": "is",
  "383": "il",
  "386": "it",
  "391": "jm",
  "393": "je",
  "399": "jp",
  "403": "jo",
  "411": "ki",
  "420": "la",
  "426": "ls",
  "427": "lv",
  "431": "lb",
  "434": "lr",
  "438": "ly",
  "440": "li",
  "442": "lt",
  "445": "lu",
  "447": "mo",
  "449": "mk",
  "450": "mg",
  "455": "my",
  "458": "mw",
  "461": "mv",
  "464": "ml",
  "467": "mt",
  "472": "mp",
  "474": "ma",
  "476": "mh",
  "477": "mq",
  "485": "mu",
  "488": "mr",
  "489": "yt",
  "493": "mx",
  "494": "md",
  "495": "mc",
  "497": "mn",
  "498": "me",
  "499": "fm",
  "501": "ms",
  "505": "mz",
  "507": "na",
  "508": "nr",
  "511": "cx",
  "517": "np",
  "521": "ni",
  "525": "ne",
  "528": "ng",
  "531": "nu",
  "535": "nf",
  "538": "no",
  "542": "nc",
  "545": "pg",
  "548": "nz",
  "551": "vu",
  "556": "om",
  "566": "pf",
  "573": "nl",
  "575": "pw",
  "576": "pk",
  "578": "ps",
  "580": "pa",
  "586": "py",
  "589": "pe",
  "593": "pn",
  "599": "pf",
  "603": "pl",
  "607": "pt",
  "611": "pr",
  "623": "ke",
  "625": "kg",
  "628": "gb",
  "640": "cf",
  "647": "do",
  "660": "re",
  "665": "zw",
  "670": "ro",
  "675": "rw",
  "676": "ru",
  "677": "sb",
  "685": "eh",
  "687": "sv",
  "690": "ws",
  "691": "as",
  "693": "bl",
  "695": "kn",
  "697": "sm",
  "698": "mf",
  "699": "sx",
  "700": "pm",
  "705": "vc",
  "710": "sh",
  "715": "lc",
  "720": "st",
  "728": "sn",
  "731": "sc",
  "735": "sl",
  "737": "rs",
  "741": "sg",
  "744": "sy",
  "748": "so",
  "750": "lk",
  "754": "sz",
  "755": "sj",
  "756": "za",
  "759": "sd",
  "760": "ss",
  "764": "se",
  "767": "ch",
  "770": "sr",
  "772": "tj",
  "776": "th",
  "780": "tz",
  "781": "tf",
  "782": "io",
  "783": "dj",
  "788": "td",
  "791": "cz",
  "795": "tl",
  "800": "tg",
  "805": "tk",
  "810": "to",
  "815": "tt",
  "820": "tn",
  "823": "tc",
  "824": "tm",
  "827": "tr",
  "828": "tv",
  "831": "ua",
  "833": "ug",
  "845": "uy",
  "847": "uz",
  "848": "va",
  "850": "ve",
  "858": "vn",
  "863": "vg",
  "866": "vi",
  "870": "fj",
  "875": "wf",
  "888": "cd",
  "890": "zm"
};

export const COUNTRY_CODES = {
  '013': { sigla: 'af', nome: 'AFEGANISTÃO' },
  '015': { sigla: 'ax', nome: 'ALAND, ILHAS' },
  '017': { sigla: 'al', nome: 'ALBANIA' },
  '023': { sigla: 'de', nome: 'ALEMANHA' },
  '031': { sigla: 'bf', nome: 'BURKINA FASO' },
  '037': { sigla: 'ad', nome: 'ANDORRA' },
  '040': { sigla: 'ao', nome: 'ANGOLA' },
  '041': { sigla: 'ai', nome: 'ANGUILLA' },
  '042': { sigla: 'aq', nome: 'ANTÁRTICA' },
  '043': { sigla: 'ag', nome: 'ANTIGUA E BARBUDA' },
  '053': { sigla: 'sa', nome: 'ARABIA SAUDITA' },
  '059': { sigla: 'dz', nome: 'ARGELIA' },
  '063': { sigla: 'ar', nome: 'ARGENTINA' },
  '064': { sigla: 'am', nome: 'ARMENIA' },
  '065': { sigla: 'aw', nome: 'ARUBA' },
  '069': { sigla: 'au', nome: 'AUSTRALIA' },
  '072': { sigla: 'at', nome: 'AUSTRIA' },
  '073': { sigla: 'az', nome: 'AZERBAIJAO' },
  '077': { sigla: 'bs', nome: 'BAHAMAS, ILHAS' },
  '080': { sigla: 'bh', nome: 'BAHREIN, ILHAS' },
  '081': { sigla: 'bd', nome: 'BANGLADESH' },
  '083': { sigla: 'bb', nome: 'BARBADOS' },
  '085': { sigla: 'by', nome: 'BELARUS' },
  '087': { sigla: 'be', nome: 'BELGICA' },
  '088': { sigla: 'bz', nome: 'BELIZE' },
  '090': { sigla: 'bm', nome: 'BERMUDAS' },
  '093': { sigla: 'mm', nome: 'MIANMAR (BIRMANIA)' },
  '097': { sigla: 'bo', nome: 'BOLIVIA' },
  '098': { sigla: 'ba', nome: 'BOSNIA-HERZEGOVINA (REPUBLICA DA)' },
  '099': { sigla: 'bq', nome: 'BONAIRE, SAINT EUSTATIUS E SABA' },
  '101': { sigla: 'bw', nome: 'BOTSUANA' },
  '102': { sigla: 'bv', nome: 'BOUVET, ILHA' },
  '105': { sigla: 'br', nome: 'BRASIL' },
  '108': { sigla: 'bn', nome: 'BRUNEI' },
  '111': { sigla: 'bg', nome: 'BULGARIA' },
  '115': { sigla: 'bi', nome: 'BURUNDI' },
  '119': { sigla: 'bt', nome: 'BUTAO' },
  '127': { sigla: 'cv', nome: 'CABO VERDE' },
  '137': { sigla: 'ky', nome: 'CAYMAN, ILHAS' },
  '141': { sigla: 'kh', nome: 'CAMBOJA' },
  '145': { sigla: 'cm', nome: 'CAMAROES' },
  '149': { sigla: 'ca', nome: 'CANADA' },
  '153': { sigla: 'kz', nome: 'CAZAQUISTAO' },
  '154': { sigla: 'qa', nome: 'CATAR' },
  '158': { sigla: 'cl', nome: 'CHILE' },
  '160': { sigla: 'cn', nome: 'CHINA' },
  '161': { sigla: 'tw', nome: 'FORMOSA (TAIWAN)' },
  '163': { sigla: 'cy', nome: 'CHIPRE' },
  '165': { sigla: 'cc', nome: 'COCOS(KEELING),ILHAS' },
  '169': { sigla: 'co', nome: 'COLOMBIA' },
  '173': { sigla: 'km', nome: 'COMORES, ILHAS' },
  '177': { sigla: 'cg', nome: 'CONGO' },
  '183': { sigla: 'ck', nome: 'COOK, ILHAS' },
  '187': { sigla: 'kp', nome: 'COREIA DO NORTE' },
  '190': { sigla: 'kr', nome: 'CORÉIA DO SUL' },
  '193': { sigla: 'ci', nome: 'COSTA DO MARFIM' },
  '195': { sigla: 'hr', nome: 'CROACIA' },
  '196': { sigla: 'cr', nome: 'COSTA RICA' },
  '198': { sigla: 'kw', nome: 'COVEITE' },
  '199': { sigla: 'cu', nome: 'CUBA' },
  '200': { sigla: 'cw', nome: 'CURACAO' },
  '229': { sigla: 'bj', nome: 'BENIN' },
  '232': { sigla: 'dk', nome: 'DINAMARCA' },
  '235': { sigla: 'dm', nome: 'DOMINICA, ILHA' },
  '239': { sigla: 'ec', nome: 'ECUADOR' },
  '240': { sigla: 'eg', nome: 'EGITO' },
  '243': { sigla: 'er', nome: 'ERITREIA' },
  '244': { sigla: 'ae', nome: 'EMIRADOS ÁRABES UNIDOS' },
  '245': { sigla: 'es', nome: 'ESPANHA' },
  '246': { sigla: 'si', nome: 'ESLOVÊNIA' },
  '247': { sigla: 'sk', nome: 'ESLOVACA' },
  '249': { sigla: 'us', nome: 'ESTADOS UNIDOS' },
  '251': { sigla: 'ee', nome: 'ESTÔNIA' },
  '253': { sigla: 'et', nome: 'ETIÓPIA' },
  '255': { sigla: 'fk', nome: 'FALKLAND (ILHAS MALVINAS)' },
  '259': { sigla: 'fo', nome: 'FEROE, ILHAS' },
  '267': { sigla: 'ph', nome: 'FILIPINAS' },
  '271': { sigla: 'fi', nome: 'FINLÂNDIA' },
  '275': { sigla: 'fr', nome: 'FRANÇA' },
  '281': { sigla: 'ga', nome: 'GABÃO' },
  '285': { sigla: 'gm', nome: 'GAMBIA' },
  '289': { sigla: 'gh', nome: 'GANA' },
  '291': { sigla: 'ge', nome: 'GEORGIA' },
  '292': { sigla: 'gs', nome: 'GEÓRGIA DO SUL E SANDWICH DO SUL, ILHAS' },
  '293': { sigla: 'gi', nome: 'GIBRALTAR' },
  '297': { sigla: 'gd', nome: 'GRANADA' },
  '301': { sigla: 'gr', nome: 'GRÉCIA' },
  '305': { sigla: 'gl', nome: 'GROELÃNDIA' },
  '309': { sigla: 'gp', nome: 'GUADALUPE' },
  '313': { sigla: 'gu', nome: 'GUAM' },
  '317': { sigla: 'gt', nome: 'GUATEMALA' },
  '321': { sigla: 'gg', nome: 'GUERNSEY' },
  '325': { sigla: 'gf', nome: 'GUIANA FRANCESA' },
  '329': { sigla: 'gn', nome: 'GUINÉ' },
  '334': { sigla: 'gw', nome: 'GUINÉ-BISSAU' },
  '331': { sigla: 'gq', nome: 'GUINÉ-EQUATORIAL' },
  '337': { sigla: 'gy', nome: 'GUIANA' },
  '341': { sigla: 'ht', nome: 'HAITI' },
  '343': { sigla: 'hm', nome: 'HEARD E ILHAS MCDONALD, ILHA' },
  '345': { sigla: 'hn', nome: 'HONDURAS' },
  '351': { sigla: 'hk', nome: 'HONG KONG' },
  '355': { sigla: 'hu', nome: 'HUNGRIA' },
  '357': { sigla: 'ye', nome: 'IEMEN' },
  '359': { sigla: 'im', nome: 'MAN, ILHA DE' },
  '361': { sigla: 'in', nome: 'ÍNDIA' },
  '365': { sigla: 'id', nome: 'INDONÉSIA' },
  '369': { sigla: 'iq', nome: 'IRAQUE' },
  '372': { sigla: 'ir', nome: 'IRÃ' },
  '375': { sigla: 'ie', nome: 'IRLANDA' },
  '379': { sigla: 'is', nome: 'ISLÂNDIA' },
  '383': { sigla: 'il', nome: 'ISRAEL' },
  '386': { sigla: 'it', nome: 'ITÁLIA' },
  '391': { sigla: 'jm', nome: 'JAMAICA' },
  '393': { sigla: 'je', nome: 'JERSEY' },
  '399': { sigla: 'jp', nome: 'JAPÃO' },
  '403': { sigla: 'jo', nome: 'JORDÂNIA' },
  '411': { sigla: 'ki', nome: 'KIRIBATI' },
  '420': { sigla: 'la', nome: 'LAOS, REP.POP.DEMOCR.DO' },
  '426': { sigla: 'ls', nome: 'LESOTO' },
  '427': { sigla: 'lv', nome: 'LETÔNIA' },
  '431': { sigla: 'lb', nome: 'LÍBANO' },
  '434': { sigla: 'lr', nome: 'LIBÉRIA' },
  '438': { sigla: 'ly', nome: 'LÍBIA' },
  '440': { sigla: 'li', nome: 'LIECHTENSTEIN' },
  '442': { sigla: 'lt', nome: 'LITUÂNIA' },
  '445': { sigla: 'lu', nome: 'LUXEMBURGO' },
  '447': { sigla: 'mo', nome: 'MACAU' },
  '449': { sigla: 'mk', nome: 'MACEDÔNIA, ANT.REP.IUGOSLAVA' },
  '450': { sigla: 'mg', nome: 'MADAGASCAR' },
  '455': { sigla: 'my', nome: 'MALÁSIA' },
  '458': { sigla: 'mw', nome: 'MALAVI' },
  '461': { sigla: 'mv', nome: 'MALDIVAS' },
  '464': { sigla: 'ml', nome: 'MALI' },
  '467': { sigla: 'mt', nome: 'MALTA' },
  '472': { sigla: 'mp', nome: 'MARIANAS DO NORTE' },
  '474': { sigla: 'ma', nome: 'MARROCOS' },
  '476': { sigla: 'mh', nome: 'MARSHALL, ILHAS' },
  '477': { sigla: 'mq', nome: 'MARTINICA' },
  '485': { sigla: 'mu', nome: 'MAURÍCIO' },
  '488': { sigla: 'mr', nome: 'MAURITÂNIA' },
  '489': { sigla: 'yt', nome: 'MAYOTTE' },
  '493': { sigla: 'mx', nome: 'MEXICO' },
  '494': { sigla: 'md', nome: 'MOLDAVIA' },
  '495': { sigla: 'mc', nome: 'MÔNACO' },
  '497': { sigla: 'mn', nome: 'MONGÓLIA' },
  '498': { sigla: 'me', nome: 'MONTENEGRO' },
  '499': { sigla: 'fm', nome: 'MICRONÉSIA' },
  '501': { sigla: 'ms', nome: 'MONTSERRAT, ILHAS' },
  '505': { sigla: 'mz', nome: 'MOÇAMBIQUE' },
  '507': { sigla: 'na', nome: 'NAMÍBIA' },
  '508': { sigla: 'nr', nome: 'NAURU' },
  '511': { sigla: 'cx', nome: 'CHRISTMAS,ILHA (NAVIDAD)' },
  '517': { sigla: 'np', nome: 'NEPAL' },
  '521': { sigla: 'ni', nome: 'NICARÁGUA' },
  '525': { sigla: 'ne', nome: 'NIGER' },
  '528': { sigla: 'ng', nome: 'NIGÉRIA' },
  '531': { sigla: 'nu', nome: 'NIUE, ILHA' },
  '535': { sigla: 'nf', nome: 'NORFOLK, ILHA' },
  '538': { sigla: 'no', nome: 'NORUEGA' },
  '542': { sigla: 'nc', nome: 'NOVA CALEDÔNIA' },
  '545': { sigla: 'pg', nome: 'PAPUA NOVA GUINÉ' },
  '548': { sigla: 'nz', nome: 'NOVA ZELÂNDIA' },
  '551': { sigla: 'vu', nome: 'VANUATU' },
  '556': { sigla: 'om', nome: 'OMÃ' },
  '566': { sigla: 'pf', nome: 'PACIFICO, ILHAS DO (POSSESSAO DOS EUA)' },
  '573': { sigla: 'nl', nome: 'HOLANDA' },
  '575': { sigla: 'pw', nome: 'PALAU' },
  '576': { sigla: 'pk', nome: 'PAQUISTÃO' },
  '578': { sigla: 'ps', nome: 'PALESTINA' },
  '580': { sigla: 'pa', nome: 'PANAMÁ' },
  '586': { sigla: 'py', nome: 'PARAGUAI' },
  '589': { sigla: 'pe', nome: 'PERU' },
  '593': { sigla: 'pn', nome: 'PITCAIRN, ILHA DE' },
  '599': { sigla: 'pf', nome: 'POLINÉSIA FRANCESA' },
  '603': { sigla: 'pl', nome: 'POLÔNIA' },
  '607': { sigla: 'pt', nome: 'PORTUGAL' },
  '611': { sigla: 'pr', nome: 'PORTO RICO' },
  '623': { sigla: 'ke', nome: 'QUÊNIA' },
  '625': { sigla: 'kg', nome: 'QUIRGUIZ' },
  '628': { sigla: 'gb', nome: 'REINO UNIDO' },
  '640': { sigla: 'cf', nome: 'REPÚBLICA CENTRO-AFRICANA' },
  '647': { sigla: 'do', nome: 'REPÚBLICA DOMINICANA' },
  '660': { sigla: 're', nome: 'REUNIÃO, ILHA' },
  '665': { sigla: 'zw', nome: 'ZIMBABUE' },
  '670': { sigla: 'ro', nome: 'ROMÊNIA' },
  '675': { sigla: 'rw', nome: 'RUANDA' },
  '676': { sigla: 'ru', nome: 'RUSSIA, FEDERAÇÃO DA' },
  '677': { sigla: 'sb', nome: 'SALOMÃO, ILHAS' },
  '685': { sigla: 'eh', nome: 'SAARA OCIDENTAL' },
  '687': { sigla: 'sv', nome: 'EL SALVADOR' },
  '690': { sigla: 'ws', nome: 'SAMOA' },
  '691': { sigla: 'as', nome: 'SAMOA AMERICANA' },
  '693': { sigla: 'gp', nome: 'SAO BARTOLOMEU' },
  '695': { sigla: 'kn', nome: 'SÃO CRISTOVÃO E NEVES, ILHAS' },
  '697': { sigla: 'sm', nome: 'SAN MARINO' },
  '698': { sigla: 'mf', nome: 'SÃO MARTINHO, ILHA DE (PARTE FRANCESA)' },
  '699': { sigla: 'nl', nome: 'SÃO MARTINHO, ILHA DE (PARTE HOLANDESA)' },
  '700': { sigla: 'pm', nome: 'SÃO PEDRO E MIQUELON' },
  '705': { sigla: 'vc', nome: 'SÃO VICENTE E GRANADINAS' },
  '710': { sigla: 'sh', nome: 'SANTA HELENA' },
  '715': { sigla: 'lc', nome: 'SANTA LÚCIA' },
  '720': { sigla: 'st', nome: 'SÃO TOMÉ E PRÍNCIPE, ILHAS' },
  '728': { sigla: 'sn', nome: 'SENEGAL' },
  '731': { sigla: 'sc', nome: 'SEYCHELLES' },
  '735': { sigla: 'sl', nome: 'SERRA LEOA' },
  '737': { sigla: 'rs', nome: 'SERVIA' },
  '741': { sigla: 'sg', nome: 'CINGAPURA' },
  '744': { sigla: 'sy', nome: 'SÍRIA, REPÚBLICA ARABE DA' },
  '748': { sigla: 'so', nome: 'SOMÁLIA' },
  '750': { sigla: 'lk', nome: 'SRI LANKA' },
  '754': { sigla: 'sz', nome: 'SUAZILÂNDIA' },
  '755': { sigla: 'sj', nome: 'SVALBARD E JAN MAYEN' },
  '756': { sigla: 'za', nome: 'AFRICA DO SUL' },
  '759': { sigla: 'sd', nome: 'SUDÃO' },
  '760': { sigla: 'ss', nome: 'SUDAO DO SUL' },
  '764': { sigla: 'se', nome: 'SUÉCIA' },
  '767': { sigla: 'ch', nome: 'SUÍCA' },
  '770': { sigla: 'sr', nome: 'SURINAME' },
  '772': { sigla: 'tj', nome: 'TADJIQUISTÃO' },
  '776': { sigla: 'th', nome: 'TAILÂNDIA' },
  '780': { sigla: 'tz', nome: 'TANZANIA, REP. UNIDA DA' },
  '781': { sigla: 'tf', nome: 'TERRAS AUSTRAIS FRANCESAS' },
  '782': { sigla: 'io', nome: 'TERRITÓRIO BRITÂNICO NO OCEANO ÍNDICO' },
  '783': { sigla: 'dj', nome: 'DJIBUTI' },
  '788': { sigla: 'td', nome: 'CHADE' },
  '791': { sigla: 'cz', nome: 'TCHECA, REPUBLICA' },
  '795': { sigla: 'tl', nome: 'TIMOR LESTE' },
  '800': { sigla: 'tg', nome: 'TOGO' },
  '805': { sigla: 'tk', nome: 'TOQUELAU, ILHAS' },
  '810': { sigla: 'to', nome: 'TONGA' },
  '815': { sigla: 'tt', nome: 'TRINIDAD E TOBAGO' },
  '820': { sigla: 'tn', nome: 'TUNÍSIA' },
  '823': { sigla: 'tc', nome: 'TURCAS E CAICOS, ILHAS' },
  '824': { sigla: 'tm', nome: 'TURCOMENISTÃO' },
  '827': { sigla: 'tr', nome: 'TURQUIA' },
  '828': { sigla: 'tv', nome: 'TUVALU' },
  '831': { sigla: 'ua', nome: 'UCRÂNIA' },
  '833': { sigla: 'ug', nome: 'UGANDA' },
  '845': { sigla: 'uy', nome: 'URUGUAI' },
  '847': { sigla: 'uz', nome: 'UZBEQUISTÃO' },
  '848': { sigla: 'va', nome: 'VATICANO' },
  '850': { sigla: 've', nome: 'VENEZUELA' },
  '858': { sigla: 'vn', nome: 'VIETNÃ' },
  '863': { sigla: 'vg', nome: 'VIRGENS, ILHAS (BRITÂNICAS)' },
  '866': { sigla: 'vi', nome: 'VIRGENS, ILHAS (E.U.A.)' },
  '870': { sigla: 'fj', nome: 'FIJI' },
  '875': { sigla: 'wf', nome: 'WALLIS E FUTUNA, ILHAS' },
  '888': { sigla: 'cd', nome: 'CONGO' },
  '890': { sigla: 'zm', nome: 'ZÂMBIA' }
}

export const COLORS = [
  "#104375", "#f26767", "#137700", "#DBB058",
  "#D16A20", "#52B33D", "#8C4DC3", "#FFD27F",  
  "#1A6BAA", "#E04E4E", "#0D3660", "#C19A40", "#A066D9",  
  "#F2803C", "#0A2C4D", "#C74444", "#2BA000", "#B889E0",  
  "#A83232", "#155A90", "#E6C373", "#5E2E85",  
  "#B38A30", "#F48B8B", "#0F5F00", "#FF9999", "#FF9933",  
  "#0D4C00", "#1675B7", "#B35617", "#753FA6", "#F1D485",  
  "#1A8800", "#FFA552"
];


export const tttttt = {};